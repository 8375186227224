import { createStore } from "version-one-dev-utils/state";
import PropTypes from "prop-types";

import { Firebase, firebase } from "../Firebase";

import { AuthStore } from "./AuthStore";

let unsubscribe;

const watch = (props) => (resolve, reject) => {
  unsubscribe && unsubscribe();

  const onSnapshot = (querySnapshot) => {
    const productions = [];

    querySnapshot.forEach((doc) => {
      productions.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    resolve(productions);
  };

  if (props.user) {
    unsubscribe = Firebase.firestore()
      .collection("productions")
      .where("members", "array-contains", props.user)
      .onSnapshot(onSnapshot, reject);
  } else {
    unsubscribe = Firebase.firestore()
      .collection("productions")
      .onSnapshot(onSnapshot, reject);
  }
};

watch.propTypes = {
  user: PropTypes.string,
};

watch.success = (state, action) => action.payload;

const add = (props) => {
  const production = {
    name: props.name,
    description: props.description,
    tags: props.tags,
    members: props.members,
    managers: props.managers,
    modifiedBy: AuthStore.getState().id,
    deleted: false,
  };

  return Firebase.firestore()
    .collection("productions")
    .add(production)
    .then((ref) => Promise.resolve(ref.id))
    .catch((error) => {
      console.error("Error", String(error));
      return Promise.reject(new Error(String(error)));
    });
};

add.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  members: PropTypes.arrayOf(PropTypes.string).isRequired,
  managers: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const edit = (props) => {
  const id = props.id;

  const production = {
    name: props.name,
    description: props.description,
    tags: props.tags,
    members: props.members,
    managers: props.managers,
    modifiedBy: AuthStore.getState().id,
    deleted: false,
  };

  return Firebase.firestore()
    .collection("productions")
    .doc(id)
    .update(production)
    .catch((error) => {
      console.error("Error", String(error));
      return Promise.reject(new Error(String(error)));
    });
};

edit.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  members: PropTypes.arrayOf(PropTypes.string).isRequired,
  managers: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const setUserRole = (props) => {
  return Firebase.firestore()
    .collection("productions")
    .doc(props.id)
    .update({
      managers:
        props.role === "MANAGER"
          ? firebase.firestore.FieldValue.arrayUnion(props.userId)
          : firebase.firestore.FieldValue.arrayRemove(props.userId),
      modifiedBy: AuthStore.getState().id,
    });
};

setUserRole.propTypes = {
  id: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  role: PropTypes.oneOf(["MEMBER", "MANAGER"]),
};

const removeUser = (props) => {
  return Firebase.firestore()
    .collection("productions")
    .doc(props.id)
    .update({
      members: firebase.firestore.FieldValue.arrayRemove(props.userId),
      managers: firebase.firestore.FieldValue.arrayRemove(props.userId),
      modifiedBy: AuthStore.getState().id,
    });
};

removeUser.propTypes = {
  id: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

const remove = (props) => {
  const id = props.id;

  const production = {
    ...ProductionStore.select.get(id),
    modifiedBy: AuthStore.getState().id,
    deleted: true,
  };

  delete production.id;

  return Firebase.firestore()
    .collection("productions")
    .doc(id)
    .update(production)
    .catch((error) => {
      console.error("Error", String(error));
      return Promise.reject(new Error(String(error)));
    });
};

remove.propTypes = {
  id: PropTypes.string.isRequired,
};

export const ProductionStore = createStore({
  name: "ProductionStore",
  initialState: [],
  actions: { watch, add, edit, setUserRole, removeUser, remove },
  propTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.string).isRequired,
      members: PropTypes.arrayOf(PropTypes.string).isRequired,
      managers: PropTypes.arrayOf(PropTypes.string).isRequired,
      deleted: PropTypes.bool.isRequired,
    })
  ),
});

ProductionStore.select = {
  get: (id) =>
    ProductionStore.getState().find((p) => p.id === id) || {
      id,
      name: "Missing Production",
      description: "Missing production description",
      tags: [],
      members: [],
      managers: [],
      deleted: false,
    },
};
