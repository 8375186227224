import React from "react";
import { Route } from "react-router-dom";

import { useRoutes } from "version-one-dev-utils/routes";
import { useSelector } from "version-one-dev-utils/state";

import { roles } from "../../stores/UserStore";

import { Table } from "../../components/Table";

import { EditUser } from "../EditUser";

const AdminUsers = () => {
  const { routes, push, match } = useRoutes();

  const role = match(routes.ADMIN_ADMINS) ? "admin" : "operator";
  const isAdmin = role === "admin";

  const items = useSelector((state) =>
    state.UserStore.filter((user) => user.role === role && !user.deleted)
  ).sort((a, b) => (a.fullName < b.fullName ? -1 : 1));

  return (
    <>
      <Table
        columns={[
          {
            name: "Name",
            key: "fullName",
            minWidth: 200,
          },
          {
            name: "Email",
            key: "email",
            minWidth: 250,
          },
        ]}
        items={items}
        addButton={{
          label: `New ${roles[role].label}`,
          onClick: () =>
            push(isAdmin ? routes.ADMIN_NEW_ADMIN : routes.ADMIN_NEW_OPERATOR),
        }}
        filter={(items, value) => {
          return items.filter(
            (item) =>
              item.fullName.toLowerCase().includes(value.toLowerCase()) ||
              item.email.toLowerCase().includes(value.toLowerCase())
          );
        }}
        filterPlaceholder="Name or email"
        placeholder={`No ${roles[role].label.toLowerCase()}s`}
        onClickRow={(item) =>
          push(
            isAdmin ? routes.ADMIN_EDIT_ADMIN : routes.ADMIN_EDIT_OPERATOR,
            item
          )
        }
      />
      <Route
        path={isAdmin ? routes.ADMIN_NEW_ADMIN : routes.ADMIN_NEW_OPERATOR}
        component={EditUser}
      />
      <Route
        path={isAdmin ? routes.ADMIN_EDIT_ADMIN : routes.ADMIN_EDIT_OPERATOR}
        component={EditUser}
      />
    </>
  );
};

export { AdminUsers };
