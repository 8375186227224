import "./LogRocket";

import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter as Router } from "react-router-dom";
import { RouteProvider } from "version-one-dev-utils/routes";

import { routes } from "./routes";

import { App } from "./App";

import "./tailwind/output.css";

ReactDOM.render(
  <RouteProvider value={routes}>
    <Router>
      <App />
    </Router>
  </RouteProvider>,
  document.getElementById("root")
);
