import React, { useEffect, useState } from "react";

import { useSelector } from "version-one-dev-utils/state";

import { AuditLogStore } from "../../stores/AuditLogStore";

import { DateString } from "../../components/DateString";
import { Table } from "../../components/Table";
import { Select } from "../../components/Select";
import { Button } from "../../components/Button";

import { UserCell } from "../UserCell";
import { EditSuiteCell } from "../EditSuiteCell";
import { UserSelect } from "../UserSelect";

const AuditLog = () => {
  const { logs, editSuites } = useSelector((state) => ({
    logs: state.AuditLogStore,
    editSuites: state.EditSuiteStore,
  }));

  const [limit, setLimit] = useState(10);
  const [user, setUser] = useState();
  const [resourceEditSuite, setResourceEditSuite] = useState();
  const [resourceUser, setResourceUser] = useState();

  let items = logs;
  if (logs.length > limit) {
    items = logs.slice(0, -1);
  }

  useEffect(() => {
    setLimit(10);
  }, [user, resourceEditSuite, resourceUser]);

  useEffect(() => {
    // Apply resources filter removing any undefined values
    const resources = [resourceEditSuite?.value, resourceUser].filter(Boolean);

    AuditLogStore.actions.get({
      limit: limit + 1,
      user,
      resources: resources.length ? resources : null,
    });
  }, [limit, user, resourceEditSuite, resourceUser]);

  return (
    <>
      <div>
        <h3 className="font-semibold mb-4">Filters</h3>
        <div className="grid grid-cols-3 items-end gap-8 mb-8 max-w-[1000px]">
          <div className="pr-8 border-r border-ui-300">
            <span className="block font-semibold text-sm mb-2 text-ui-500">
              User
            </span>
            <UserSelect
              className="max-w-[300px]"
              isClearable
              name="users"
              placeholder="User"
              value={user}
              onChange={setUser}
            />
          </div>

          <div>
            <span className="block font-semibold text-sm mb-2 text-ui-500">
              Resource
            </span>

            <Select
              className="inline-block w-full max-w-[300px] "
              isClearable
              placeholder="Edit Suite"
              options={editSuites
                .map((editSuite) => ({
                  value: editSuite.id,
                  label: `${editSuite.name}  (${editSuite.id})`,
                }))
                .sort((a, b) => (a.label < b.label ? -1 : 1))}
              value={resourceEditSuite}
              onChange={(editSuite) => setResourceEditSuite(editSuite)}
            />
          </div>

          <UserSelect
            className="inline-block w-full max-w-[300px]"
            isClearable
            placeholder="User"
            value={resourceUser}
            onChange={setResourceUser}
          />
        </div>
      </div>

      <Table
        columns={[
          {
            name: "Action",
            key: "name",
            render: (item) => <span className="text-sm">{item.name}</span>,
          },
          {
            name: "User",
            key: "user",
            render: (item) => <UserCell id={item.user} />,
          },

          {
            name: "Resource",
            key: "resource",
            render: (item) => {
              if (!item.resource) {
                return "-";
              } else if (item.resource.type === "USER") {
                return <UserCell id={item.resource.id} />;
              } else {
                return <EditSuiteCell id={item.resource.id} />;
              }
            },
          },
          {
            name: "Date",
            key: "timestamp",
            render: (item) => (
              <DateString
                value={item.timestamp.toString()}
                className="text-sm"
              />
            ),
          },
        ]}
        items={items}
      />
      {logs.length > limit && (
        <div className="flex justify-center">
          <Button
            label="Load More"
            size="small"
            color="primary"
            className="mt-8"
            onClick={() => setLimit(limit + 10)}
          />
        </div>
      )}
    </>
  );
};

export { AuditLog };
