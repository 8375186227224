import React from "react";

import { useRoutes } from "version-one-dev-utils/routes";
import { useSelector } from "version-one-dev-utils/state";

import { Link } from "react-router-dom";

import { ProductionStore } from "../../stores/ProductionStore";
import { InviteStore } from "../../stores/InviteStore";

import { Field } from "../../components/Field";
// import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import { Modal } from "../../components/Modal";
import { TagInputForForm } from "../../components/TagInputForForm";

import { useForm } from "../../deps/useForm";
import { Form } from "../../deps/Form";
import { array, string } from "yup";

const InviteUsers = (props) => {
  const { params, routes, link, push } = useRoutes();

  const production = useSelector(() =>
    ProductionStore.select.get(params.productionId)
  );

  const form = useForm({
    schema: {
      production: string(),
      emails: array()
        .of(string().email("Please enter a valid email address"))
        .min(1, "Please enter an email address"),
    },
    defaultValues: {
      production: production.id,
      emails: [],
    },
    onSubmit: (values) => InviteStore.actions.invite(values).then(close),
  });

  const {
    formState: { errors, isSubmitting },
  } = form;

  const close = () => push(routes.OPERATOR_USERS, params);

  return (
    <Modal title="Invite Users" onClose={close}>
      <Form config={form}>
        <p className="mb-8">
          Enter the email addresses below of anyone you would like to join{" "}
          <span className="italic">{production.name}</span>
        </p>
        <Field
          label="Email addresses"
          error={errors.emails?.find && errors.emails.find((err) => err)}
        >
          <TagInputForForm
            name="emails"
            className="w-full"
            errors={errors.emails?.find && errors.emails}
          />
        </Field>

        <div className="flex items-center mt-16">
          <div className="flex-1 mr-8">
            <Link
              className="font-semibold underline hover:no-underline"
              to={link(routes.OPERATOR_USERS, params)}
            >
              Cancel
            </Link>
          </div>
          <Button label="Send Invite" color="primary" pending={isSubmitting} />
        </div>
      </Form>
    </Modal>
  );
};

export { InviteUsers };
