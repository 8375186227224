import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as Logo } from "../../images/logo.svg";
import BackgroundImage from "../../images/bg-new.jpg";

/** A wrapper for the Login, ForgotPassword and SetPassword view to provide consistant styling */
const AccessPage = (props) => {
  const { children } = props;

  return (
    <div className="bg-black h-screen flex flex-col items-center justify-center">
      <div
        className="bg-cover bg-center	opacity-60 h-screen w-full absolute"
        style={{
          backgroundImage: `url(${BackgroundImage})`,
        }}
      />
      <div className="p-8 relative flex flex-col items-center justify-center">
        <Logo className="mb-12" />
        <div>{children}</div>
      </div>
    </div>
  );
};

AccessPage.propTypes = {
  /** Component to render in the view */
  children: PropTypes.any,
};

export { AccessPage };
