import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Icon } from "../Icon";

const Tag = (props) => {
  const { label, className, type, onDelete } = props;

  return (
    <span
      className={classnames(
        "inline-block rounded-full text-xs font-semibold py-1 px-3",
        type === "light" && "bg-white border border-pink-500",
        type === "dark" &&
          "bg-black bg-opacity-30 border border-white border-opacity-30 text-white",
        className
      )}
    >
      {label}
      {onDelete && (
        <Icon
          icon={["fas", "times"]}
          className="ml-2 cursor-pointer text-grey500 hover:text-grey400"
          onClick={onDelete}
        />
      )}
    </span>
  );
};

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(["light", "dark"]),
  onDelete: PropTypes.func,
};

Tag.defaultProps = {
  type: "light",
};

export { Tag };
