import { createStore } from "version-one-dev-utils/state";
import PropTypes from "prop-types";

import { Firebase, firebase } from "../Firebase";

import { AuthStore } from "./AuthStore";
import { AuditLogStore } from "./AuditLogStore";

import jwt from "jsonwebtoken";

let unsubscribe;

const watch = (props) => (resolve, reject) => {
  unsubscribe && unsubscribe();

  const onSnapshot = (querySnapshot) => {
    const editSuites = [];

    querySnapshot.forEach((doc) => {
      editSuites.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    resolve(editSuites);
  };

  if (props.user && props.production) {
    unsubscribe = Firebase.firestore()
      .collection("editsuites")
      .where("user", "==", props.user)
      .where("production", "==", props.production)
      .onSnapshot(onSnapshot, reject);
  } else if (props.production) {
    unsubscribe = Firebase.firestore()
      .collection("editsuites")
      .where("production", "==", props.production)
      .onSnapshot(onSnapshot, reject);
  } else {
    unsubscribe = Firebase.firestore()
      .collection("editsuites")
      .onSnapshot(onSnapshot, reject);
  }
};

watch.propTypes = {
  production: PropTypes.string,
  user: PropTypes.string,
};

watch.success = (state, action) => action.payload;

const unwatch = () => Promise.resolve(unsubscribe && unsubscribe());
unwatch.propTypes = {};
unwatch.success = (state, action) => [];

const add = (props) => {
  const editSuite = {
    production: props.production,
    name: props.name,
    tags: props.tags,
    modifiedBy: AuthStore.getState().id,
    deleted: false,
  };

  if (props.connection) {
    editSuite.connection = props.connection;
  }

  if (props.user) {
    editSuite.user = props.user;
  }

  return Firebase.firestore()
    .collection("editsuites")
    .add(editSuite)
    .catch((error) => {
      console.error("Error", String(error));
      return Promise.reject(new Error(String(error)));
    });
};

add.propTypes = {
  production: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  connection: PropTypes.shape({
    broker: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    virtualMachine: PropTypes.string.isRequired,
  }),
  user: PropTypes.string,
};

const edit = (props) => {
  const id = props.id;

  const editSuite = {
    name: props.name,
    tags: props.tags,
    modifiedBy: AuthStore.getState().id,
    deleted: false,
  };

  if (props.connection) {
    editSuite.connection = props.connection;
  } else {
    editSuite.connection = firebase.firestore.FieldValue.delete();
  }

  if (props.user) {
    editSuite.user = props.user;
  } else {
    editSuite.user = firebase.firestore.FieldValue.delete();
  }

  return Firebase.firestore()
    .collection("editsuites")
    .doc(id)
    .update(editSuite)
    .catch((error) => {
      console.error("Error", String(error));
      return Promise.reject(new Error(String(error)));
    });
};

edit.propTypes = {
  id: PropTypes.string.isRequired,
  production: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  connection: PropTypes.shape({
    broker: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    virtualMachine: PropTypes.string.isRequired,
  }),
  user: PropTypes.string,
};

const remove = (props) => {
  const id = props.id;

  const editSuite = {
    ...EditSuiteStore.select.get(id),
    modifiedBy: AuthStore.getState().id,
    deleted: true,
  };

  delete editSuite.id;

  return Firebase.firestore()
    .collection("editsuites")
    .doc(id)
    .update(editSuite)
    .catch((error) => {
      console.error("Error", String(error));
      return Promise.reject(new Error(String(error)));
    });
};

remove.propTypes = {
  id: PropTypes.string.isRequired,
};

const connect = (props) => {
  const user = AuthStore.getState().id;

  if (user) {
    AuditLogStore.actions.add({
      name: "CONNECTION_INITIATED",
      user,
      resourceId: props.id,
    });
  }

  const jwToken = jwt.sign(
    {
      usr: props.connection.username,
      dom: props.connection.domain,
      vm: props.connection.virtualMachine,
    },
    "12345"
  );

  window.open(
    `pcoip://${props.connection.broker}/connect?data=${jwToken}`,
    "_self"
  );

  return Promise.resolve();
};

connect.propTypes = {
  id: PropTypes.string.isRequired,
  connection: PropTypes.shape({
    broker: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    virtualMachine: PropTypes.string.isRequired,
  }).isRequired,
};

const request = (props) => {
  return fetch(
    `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/requestEditSuite`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...props,
        appHostname: process.env.REACT_APP_HOSTNAME,
      }),
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return Promise.resolve();
      } else {
        return res.json().then((resJson) => {
          return Promise.reject(resJson.error);
        });
      }
    })
    .catch((error) => {
      console.error("Error", String(error));
      return Promise.reject(new Error(String(error)));
    });
};

request.propTypes = {
  production: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  software: PropTypes.string,
  softwarePlugins: PropTypes.arrayOf(PropTypes.string),
  softwareSpec: PropTypes.string.isRequired,
  user: PropTypes.string,
};

export const EditSuiteStore = createStore({
  name: "EditSuiteStore",
  initialState: [],
  actions: { watch, unwatch, add, edit, remove, connect, request },
  propTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      production: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      connection: PropTypes.shape({
        broker: PropTypes.string.isRequired,
        domain: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
        password: PropTypes.string.isRequired,
        virtualMachine: PropTypes.string.isRequired,
      }),
      tags: PropTypes.arrayOf(PropTypes.string).isRequired,
      user: PropTypes.string,
      deleted: PropTypes.boolean,
    })
  ),
});

EditSuiteStore.select = {
  get: (id) =>
    EditSuiteStore.getState().find((w) => w.id === id) || {
      id,
      production: "",
      name: "Missing Edit Suite",
      connection: null,
      tags: [],
      user: null,
      deleted: false,
    },
};
