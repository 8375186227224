import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";

import { Select } from "../Select";

const SelectForForm = forwardRef((props, ref) => {
  const { name, isMulti, ...otherProps } = props;

  const {
    field: { value = isMulti ? [] : "", onChange, onBlur },
  } = useController({
    name,
  });

  return (
    <Select
      {...otherProps}
      isMulti={isMulti}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
});

SelectForForm.propTypes = {
  name: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
};

export { SelectForForm };
