import React from "react";
import PropTypes from "prop-types";

const InfoCell = (props) => {
  const { name, description } = props;

  return (
    <>
      <span>{name}</span>
      <span className="block text-xs text-ui-400">{description}</span>
    </>
  );
};

InfoCell.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export { InfoCell };
