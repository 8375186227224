import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Input } from "../Input";

import { ReactComponent as Show } from "./eye.svg";
import { ReactComponent as Hide } from "./eye-slash.svg";

const PasswordInput = forwardRef((props, ref) => {
  const { className, size, autoComplete, ...inputProps } = props;
  const [show, setShow] = useState(false);

  const Icon = show ? Hide : Show;

  return (
    <div className="relative">
      <Input
        {...inputProps}
        size={size}
        className={classnames(
          className,
          (size === "small" || size === "medium") && "pr-8",
          size === "large" && "pr-10"
        )}
        ref={ref}
        type={show ? "text" : "password"}
        autoComplete={autoComplete}
      />
      <div
        className={classnames(
          "absolute top-0 bottom-0 right-0 flex items-center",
          size === "small" && "py-1 px-2",
          size === "medium" && "p-2",
          size === "large" && "p-4"
        )}
      >
        <Icon
          className="w-[1em] cursor-pointer"
          onClick={() => setShow(!show)}
        />
      </div>
    </div>
  );
});

PasswordInput.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  className: PropTypes.string,
  autoComplete: PropTypes.string,
};

PasswordInput.default = {
  size: "medium",
  autoComplete: "current-password",
};

export { PasswordInput };
