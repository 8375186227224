import React from "react";

import { useSelector } from "version-one-dev-utils/state";

import { AuthStore } from "../../stores/AuthStore";

import { ReactComponent as Logo } from "../../images/logo.svg";

import BackgroundImage from "../../images/bg-new.jpg";

const Header = () => {
  const user = useSelector((state) => state.AuthStore);

  const loggedIn = !!user.id;

  return (
    <header
      className="relative bg-cover bg-grey-700"
      style={{
        backgroundImage: `url(${BackgroundImage})`,
      }}
    >
      <div className="flex px-8 py-4 items-center relative">
        <div className="flex-1">
          <Logo />
        </div>
        {loggedIn && (
          <div className="flex items-center">
            <div className="flex items-center justify-center bg-violet-500 w-[45px] h-[45px] rounded-full pointer-events-none shadow-md">
              <span className="text-white font-bold text-lg">
                {user.firstName.charAt(0)}
              </span>
            </div>
            <div
              className="ml-4"
              style={{
                textShadow: "0 2px 10px rgba(0, 0, 50, 0.1)",
              }}
            >
              <span className="block font-bold text-white leading-none">
                {user.firstName}
              </span>
              <span
                className="text-blue-500 text-xs underline hover:no-underline cursor-pointer"
                onClick={AuthStore.actions.logout}
              >
                Log out
              </span>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export { Header };
