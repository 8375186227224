import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { TestId } from "version-one-dev-utils/tests";

export const InputTwo = forwardRef((props, ref) => {
  const testId = TestId(props);

  const { size, valid, type, className, ...other } = props;
  const [_value, setValue] = React.useState(props.value);
  const _valueRef = React.useRef(_value);

  React.useEffect(() => {
    if (props.value !== _valueRef.current) setValue(props.value);
  }, [props.value, setValue]);

  React.useEffect(() => {
    _valueRef.current = _value;
  }, [_value]);

  return React.createElement(type === "textarea" ? "textarea" : "input", {
    ...testId(),
    type: type !== "textarea" ? type : null,
    className: classnames(
      "p-[16px] rounded-[4px] bg-[#020214] text-[#c1c5da] border border-[#303460]",
      size === "l" && "text-lg p-4",
      size === "xl" && "text-xl p-5",
      !valid && "border-pink-500 focus:border-pink-500 hover:border-pink-600",
      className
    ),
    ...other,
    onChange: (e) => {
      setValue(e.target.value);
      props.onChange(e);
    },
    value: _value,
    ref,
  });
});

InputTwo.propTypes = {
  "data-testid": PropTypes.string,
  value: PropTypes.any,
  className: PropTypes.string,
  size: PropTypes.oneOf(["m", "l", "xl"]),
  valid: PropTypes.bool,
  type: PropTypes.oneOf([
    "text",
    "email",
    "password",
    "number",
    "textarea",
    "time",
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

InputTwo.defaultProps = {
  size: "m",
  valid: true,
};
