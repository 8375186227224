import React, { useState } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

import { useRoutes } from "version-one-dev-utils/routes";
import { useErrors } from "version-one-dev-utils/state";

import { AuthStore } from "../../stores/AuthStore";

import { Form } from "../../deps/Form";
import { useForm } from "../../deps/useForm";
import { string } from "yup";

import { Field } from "../../components/Field";
import { InputTwo } from "../../components/InputTwo";
import { Button } from "../../components/Button";
import { Footer } from "../Footer";
import { ReactComponent as Logo } from "./logo.svg";

import BackgroundImage from "../../images/bg-copy.jpg";

import styles from "./index.module.css";

const ResetPassword = () => {
  const [email, setEmail] = useState();

  const { routes, link } = useRoutes();

  const { getError } = useErrors();
  const error = getError(AuthStore.actions.resetPassword);

  const form = useForm({
    schema: {
      email: string()
        .required("Please enter an email address")
        .email("Invalid email address"),
    },
    onSubmit: (value) =>
      AuthStore.actions
        .resetPassword(value)
        .then((action) => setEmail(action.payload)),
  });

  const {
    register,
    formState: { errors, isSubmitting },
  } = form;

  return (
    <div className="h-screen flex flex-col">
      <div className="bg-black flex flex-col items-center justify-between h-full">
        <header className="relative w-full px-8 pt-8 z-50">
          <Logo />
        </header>
        <div
          className="bg-cover bg-center	opacity-60 h-full w-screen absolute"
          style={{
            backgroundImage: `url(${BackgroundImage})`,
          }}
        />
        {!email && (
          <Form config={form} className="w-[500px] relative z-50">
            <h1 className="text-3xl font-semibold mb-[20px] text-center text-white relative z-50">
              Forgot your password?
            </h1>
            {error && (
              <div className="text-pink-500 text-center font-semibold mb-8">
                {error.value.message}
              </div>
            )}
            <Field
              className="mb-10"
              labelColor="white"
              error={errors.email}
              errorMessageSize="large"
            >
              <InputTwo
                {...register("email")}
                placeholder="Email address"
                type="email"
                size="l"
                className={classnames(
                  styles.placeholderStyle,
                  styles.inputStyle
                )}
              />
            </Field>
            <Button
              label="Send password reset"
              color="primary"
              size="large"
              pending={isSubmitting}
              className="w-full rounded-[8px] text-lg"
            />
            <div
              className="text-center mt-5 relative z-50"
              style={{ color: "#C2C6DB" }}
            >
              <Link
                className="underline hover:no-underline"
                to={link(routes.LOGIN)}
              >
                Back to login
              </Link>
            </div>
          </Form>
        )}
        {email && (
          <div className="text-center font-semibold text-2xl text-white">
            <p className="mb-8">
              Instructions on how to reset your password have been sent to:
            </p>
            <p>{email}</p>
          </div>
        )}

        <div className="w-full">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export { ResetPassword };
