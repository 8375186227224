import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { InputTag } from "./components/InputTag";

function TagInput(props) {
  const { value, onChange, ...otherProps } = props;
  const [tags, setTags] = useState(value);

  useEffect(() => {
    onChange(tags);
  }, [tags, onChange]);

  return <InputTag value={tags} onChange={setTags} {...otherProps} />;
}

TagInput.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  className: PropTypes.string,
  errors: PropTypes.array,
};

export { TagInput };
