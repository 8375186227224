import { createStore } from "version-one-dev-utils/state";
import PropTypes from "prop-types";

import { Firebase } from "../Firebase";
import { LogRocketIdentify } from "../LogRocket";

import { AuditLogStore } from "./AuditLogStore";

let unsubscribe;

const watch = () => (resolve, reject) =>
  Firebase.auth().onAuthStateChanged((user) => {
    unsubscribe && unsubscribe();

    if (!user) {
      // If user was previously logged in reload to clean up
      if (AuthStore.getState().id) {
        window.location.reload();
      } else {
        resolve(null);
      }
    } else {
      unsubscribe = Firebase.firestore()
        .collection("users")
        .doc(user.uid)
        .onSnapshot((doc) => {
          const value = {
            id: user.uid,
            ...doc.data(),
          };

          if (!AuthStore.getState().id) {
            AuditLogStore.actions.add({
              name:
                value.role === "admin"
                  ? "ADMIN_LOGGED_IN"
                  : "OPERATOR_LOGGED_IN",
              user: value.id,
            });
          }

          LogRocketIdentify(value.id, value.email, value.fullName, value.role);

          resolve(value);
        }, reject);
    }
  });

watch.success = (state, action) => ({
  ...action.payload,
  ready: true,
});

const signup = (props) => {
  return fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/signUp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...props,
      appHostname: process.env.REACT_APP_HOSTNAME,
    }),
  })
    .then((res) => {
      if (res.status === 200) {
        return AuthStore.actions.login(props);
      } else {
        return res.json().then((resJson) => {
          return Promise.reject(resJson.error);
        });
      }
    })
    .catch((error) => {
      console.error("Error", String(error));
      return Promise.reject(new Error(String(error)));
    });
};

signup.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastNames: PropTypes.string.isRequired,
  dateOfBirth: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  company: PropTypes.string,
};

const register = (props) => {
  return Promise.resolve()
    .then(() => {
      return fetch("https://web-api.vhost.work/sendRegisterInterest", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...props,
          appHostname: process.env.REACT_APP_HOSTNAME,
        }),
      });
    })
    .then((res) => {
      if (res.status === 200) {
        return Promise.resolve();
      } else {
        return Promise.reject();
      }
    })
    .then(() => {
      alert(
        "Thank you for registering your interest! We'll be in touch shortly."
      );
      window.location.reload();
    })
    .catch(() => {
      alert("Sorry! Something went wrong.");
      window.location.reload();
    });
};

register.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};

const login = (props) =>
  Firebase.auth()
    .signInWithEmailAndPassword(props.email, props.password)
    .catch((error) => {
      console.error("Error", String(error));
      return Promise.reject(new Error(String(error)));
    });

login.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
};

const logout = () => {
  const state = AuthStore.getState();

  return AuditLogStore.actions
    .add({
      name: state.role === "admin" ? "ADMIN_LOGGED_OUT" : "OPERATOR_LOGGED_OUT",
      user: state.id,
    })
    .then(() => Firebase.auth().signOut())
    .catch((error) => {
      console.error("Error", String(error));
      return Promise.reject(new Error(String(error)));
    });
};

const resetPassword = (props) => {
  return fetch(
    `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/sendPasswordResetEmail`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...props,
        appHostname: process.env.REACT_APP_HOSTNAME,
      }),
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return Promise.resolve(props.email);
      } else {
        return res.json().then((resJson) => {
          return Promise.reject(resJson.error);
        });
      }
    })
    .catch((error) => {
      console.error("Error", String(error));
      return Promise.reject(new Error(String(error)));
    });
};

resetPassword.propTypes = {
  email: PropTypes.string.isRequired,
};

const setPassword = (props) => {
  return Promise.resolve()
    .then(() => {
      if (!props.generatePasswordResetCode) {
        return Promise.resolve(decodeURIComponent(props.passwordResetCode));
      } else {
        return fetch(
          `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/generatePasswordResetCode`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              inviteCode: decodeURIComponent(props.passwordResetCode),
            }),
          }
        ).then((res) => {
          if (res.status === 200) {
            return res.json().then((resJson) => {
              return Promise.resolve(resJson.passwordResetCode);
            });
          } else {
            return res.json().then((resJson) => {
              return Promise.reject(resJson.error);
            });
          }
        });
      }
    })
    .then((passwordResetCode) => {
      return Firebase.auth().confirmPasswordReset(
        passwordResetCode,
        props.password
      );
    })
    .catch((error) => {
      console.error("Error", String(error));
      return Promise.reject(new Error(String(error)));
    });
};

setPassword.propTypes = {
  password: PropTypes.string.isRequired,
  passwordResetCode: PropTypes.string.isRequired,
  generatePasswordResetCode: PropTypes.string,
};

const agreeTerms = () => {
  return Firebase.firestore()
    .collection("users")
    .doc(AuthStore.getState().id)
    .update({
      agreedTerms: true,
      modifiedBy: AuthStore.getState().id,
    })
    .catch((error) => {
      console.error("Error", String(error));
      return Promise.reject(new Error(String(error)));
    });
};

export const AuthStore = createStore({
  name: "AuthStore",
  initialState: {
    ready: false,
    id: null,
    email: null,
    fullName: null,
    displayName: null,
    role: null,
    agreedTerms: false,
  },
  actions: {
    watch,
    signup,
    register,
    login,
    logout,
    resetPassword,
    setPassword,
    agreeTerms,
  },
  propTypes: PropTypes.shape({
    ready: PropTypes.bool,
    id: PropTypes.string,
    email: PropTypes.string,
    fullName: PropTypes.string,
    displayName: PropTypes.string,
    role: PropTypes.oneOf(["admin", "operator"]),
    agreedTerms: PropTypes.bool,
  }),
});
