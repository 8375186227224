import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library as faLibrary } from "@fortawesome/fontawesome-svg-core";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

export const icons = {
  faSearch,
  faTimes,
};

export const iconNames = Object.values(icons).map(
  (icon) => `${icon.prefix} ${icon.iconName}`
);

Object.values(icons).forEach((icon) => faLibrary.add(icon));

export const Icon = (props) => {
  const { className, ...iconProps } = props;
  return (
    <span className={classnames("inline-block", className)}>
      <FontAwesomeIcon {...iconProps} />
    </span>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.array.isRequired,
};
