import { createStore } from "version-one-dev-utils/state";
import PropTypes from "prop-types";

import { Firebase, firebase } from "../Firebase";

const get = (props) => {
  let query = Firebase.firestore()
    .collection("logs")
    .orderBy("timestamp", "desc")
    .limit(props.limit);

  if (props.user) {
    query = query.where("user", "==", props.user);
  }

  if (props.resources) {
    query = query.where("resource.id", "in", props.resources);
  }

  return query.get().then((docs) => {
    const logs = [];

    docs.forEach((doc) => {
      const data = doc.data();
      logs.push({
        id: doc.id,
        ...data,
        timestamp: data.timestamp ? data.timestamp.toDate() : new Date(),
      });
    });

    return logs;
  });
};

get.propTypes = {
  limit: PropTypes.number.isRequired,
  user: PropTypes.string,
  resources: PropTypes.arrayOf(PropTypes.string),
};

get.success = (state, action) => {
  return action.payload;
};

const add = (props) => {
  const { resourceId, ...value } = props;

  if (resourceId) {
    value.resource = {
      id: resourceId,
      type: "EDIT_SUITE",
    };
  }

  value.timestamp = firebase.firestore.FieldValue.serverTimestamp();

  return Firebase.firestore()
    .collection("logs")
    .add(value)
    .catch((error) => {
      console.error("Error", String(error));
      return Promise.reject(new Error(String(error)));
    });
};

add.propTypes = {
  name: PropTypes.oneOf([
    "ADMIN_LOGGED_IN",
    "ADMIN_LOGGED_OUT",
    "OPERATOR_LOGGED_IN",
    "OPERATOR_LOGGED_OUT",
    "CONNECTION_INITIATED",
  ]).isRequired,
  user: PropTypes.string.isRequired,
  resourceId: PropTypes.string,
};

export const AuditLogStore = createStore({
  name: "AuditLogStore",
  initialState: [],
  actions: { get, add },
  propTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.oneOf([
        "ADMIN_LOGGED_IN",
        "ADMIN_LOGGED_OUT",
        "OPERATOR_LOGGED_IN",
        "OPERATOR_LOGGED_OUT",
        "WORKSPACE_MODIFIED",
        "EDIT_SUITE_MODIFIED",
        "USER_MODIFIED",
        "CONNECTION_INITIATED",
      ]).isRequired,
      user: PropTypes.string.isRequired,
      resource: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.oneOf(["USER", "WORKSPACE", "EDIT_SUITE"]).isRequired,
        before: PropTypes.object,
        after: PropTypes.object,
      }),
    })
  ),
});
