import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { ReactComponent as CloseIcon } from "./close.svg";

const Modal = (props) => {
  const { title, children, size, className, onClose } = props;

  return (
    <div
      className="fixed inset-0 bg-grey-800 bg-opacity-90 flex items-center justify-center z-10"
      onClick={onClose}
    >
      <div
        className={classnames(
          "relative max-w-[900px] max-h-[80%] bg-white p-8 overflow-scroll rounded-lg shadow-lg",
          size === "medium" && "w-2/3",
          className
        )}
        style={{ overflow: "auto" }}
        onClick={onClose ? (e) => e.stopPropagation() : null}
      >
        {onClose && (
          <CloseIcon
            className="absolute top-8 right-8 cursor-pointer"
            onClick={onClose}
          />
        )}
        {title && (
          <h2
            className={classnames(
              "font-bold mb-10",
              size === "medium" && "text-3xl",
              onClose && "pr-8"
            )}
          >
            {title}
          </h2>
        )}
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium"]),
  children: PropTypes.any,
  className: PropTypes.string,
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  size: "medium",
};

export { Modal };
