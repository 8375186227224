export const routes = {
  NOT_FOUND: "/404",
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  SIGNUP: "/signuptesting",
  RESET_PASSWORD: "/reset-password",
  SET_PASSWORD: "/password/:passwordResetCode",
  SET_PASSWORD_FROM_INVITE:
    "/password/:passwordResetCode/:generatePasswordResetCode",
  ADMIN: "/admin/:subRoute",
  ADMIN_PRODUCTIONS: "/admin/productions",
  ADMIN_NEW_PRODUCTION: "/admin/productions/new",
  ADMIN_PRODUCTION: "/admin/productions/view/:id",
  ADMIN_EDIT_PRODUCTION: "/admin/productions/view/:id/edit",
  ADMIN_PRODUCTION_ADD_USERS: "/admin/productions/view/:id/users",
  ADMIN_PRODUCTION_EDIT_SUITE: "/admin/productions/view/:id/:editSuite",
  ADMIN_OPERATORS: "/admin/users",
  ADMIN_NEW_OPERATOR: "/admin/users/new",
  ADMIN_EDIT_OPERATOR: "/admin/users/edit/:id",
  ADMIN_ADMINS: "/admin/admins",
  ADMIN_NEW_ADMIN: "/admin/admins/new",
  ADMIN_EDIT_ADMIN: "/admin/admins/edit/:id",
  ADMIN_LOG: "/admin/log",
  OPERATOR: "/productions/:productionId?",
  OPERATOR_USERS: "/productions/:productionId/users",
  OPERATOR_INVITE: "/productions/:productionId/users/invite",
  OPERATOR_REQUEST_EDIT_SUITE: "/productions/:productionId/requestEditSuite",
  OPERATOR_EDIT_SUITE: "/productions/:productionId/:editSuite",
};
