import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const Field = (props) => {
  const {
    label,
    children,
    type,
    error,
    disabled,
    labelColor,
    className,
    errorMessageSize,
  } = props;

  return (
    <div
      className={classnames("relative", disabled && "opacity-50", className)}
    >
      {label && (
        <label
          className={classnames(
            labelColor && `text-${labelColor}`,
            type === "default" && "block mb-4 font-semibold",
            type === "sub" && "block mb-2 text-xs font-semibold text-ui-500"
          )}
        >
          {label}
        </label>
      )}
      {React.cloneElement(children, {
        className: [children.props.className, "w-full"].join(" "),
      })}
      {error && (
        <span
          className={classnames(
            errorMessageSize === "small" && "text-xs",
            "absolute block text-pink-500  mt-1"
          )}
        >
          {error.message}
        </span>
      )}
    </div>
  );
};

Field.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(["default", "sub"]),
  disabled: PropTypes.bool,
  labelColor: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  errorMessageSize: PropTypes.oneOf(["small", "large"]),
  className: PropTypes.string,
  children: PropTypes.any,
};

Field.defaultProps = {
  type: "default",
  disabled: false,
  errorMessageSize: "small",
};

export { Field };
