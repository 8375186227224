import React, { useEffect, useMemo, useState } from "react";

import { useRoutes } from "version-one-dev-utils/routes";
import { useSelector } from "version-one-dev-utils/state";

import { Link } from "react-router-dom";

import { UserStore, roles } from "../../stores/UserStore";

import { Field } from "../../components/Field";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import { Modal } from "../../components/Modal";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { DatePickerForForm } from "../../components/DatePickerForForm";

import { useForm } from "../../deps/useForm";
import { Form } from "../../deps/Form";
import { string, number } from "yup";

const EditUser = () => {
  const { params, routes, link, push, match } = useRoutes();

  const [isDeleting, setIsDeleting] = useState(false);

  const role = match(routes.ADMIN_ADMINS) ? "admin" : "operator";
  const closeRoute =
    role === "admin" ? routes.ADMIN_ADMINS : routes.ADMIN_OPERATORS;

  const defaultUser = useMemo(
    () => ({
      firstName: "",
      lastNames: "",
      email: "",
      dateOfBirth: null,
      role,
      company: "",
    }),
    [role]
  );

  const user = useSelector(
    (state) =>
      state.UserStore.find((user) => user.id === params.id) || defaultUser
  );

  const isEditing = !!user.id;

  const form = useForm({
    schema: {
      firstName: string().required("Please enter a first name"),
      lastNames: string().required("Please enter last name(s)"),
      dateOfBirth: number()
        .typeError("Please enter date of birth")
        .required("Please enter date of birth"),
      email: string()
        .required("Please enter an email address")
        .email("Invalid email"),
      company: string(),
    },
    defaultValues: user,
    onSubmit: (values) => {
      return (
        isEditing
          ? UserStore.actions.edit(values, user.id)
          : UserStore.actions.add(values)
      ).then(() => push(closeRoute));
    },
  });

  const {
    register,
    reset,
    formState: { errors, isSubmitting },
  } = form;

  /**
   * Async default values
   */
  useEffect(() => {
    reset(user);
  }, [user, reset]);

  return isDeleting ? (
    <ConfirmationModal
      title={`Delete ${user.fullName} from ${roles[
        role
      ].label.toLowerCase()}s?`}
      confirm="Yes"
      dismiss="No"
      onConfirm={() =>
        UserStore.actions.remove(user, user.id).then(() => push(closeRoute))
      }
      onClose={() => setIsDeleting(false)}
    />
  ) : (
    <Modal
      title={`${isEditing ? "Edit" : "New"} ${roles[role].label}`}
      onClose={() => push(closeRoute)}
    >
      <Form config={form}>
        <Field className="mb-10" label="First name" error={errors.firstName}>
          <Input {...register("firstName")} />
        </Field>

        <Field className="mb-10" label="Last name(s)" error={errors.lastNames}>
          <Input {...register("lastNames")} />
        </Field>

        <Field className="mb-10" label="Email address" error={errors.email}>
          <Input {...register("email")} readOnly={isEditing} />
        </Field>

        <Field
          className="mb-10"
          label="Date of Birth"
          error={errors.dateOfBirth}
        >
          <DatePickerForForm name="dateOfBirth" />
        </Field>

        <Field className="mb-10" label="Company" error={errors.company}>
          <Input {...register("company")} />
        </Field>

        {isEditing && (
          <Button
            className="mt-5"
            type="button"
            label={`Delete ${roles[role].label}`}
            onClick={() => setIsDeleting(true)}
          />
        )}

        <div className="flex items-center mt-16">
          <div className="flex-1 mr-8">
            <Link
              className="font-semibold underline hover:no-underline"
              to={link(closeRoute)}
            >
              Cancel
            </Link>
          </div>

          <Button
            label={`Save ${roles[role].label}`}
            color="primary"
            pending={isSubmitting}
          />
        </div>
      </Form>
    </Modal>
  );
};

export { EditUser };
