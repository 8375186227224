import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./index.module.css";

const Toggle = (props) => {
  const { value, onChange } = props;

  return (
    <button
      type="button"
      onClick={() => {
        onChange && onChange(!value);
      }}
      className={classnames(
        `${styles.background}`,
        (!value && "bg-white") || (value && `${styles.gradient}`)
      )}
    >
      <span
        className={classnames(
          `${styles.toggle}`,
          value && `${styles.transform}`
        )}
      />
    </button>
  );
};

Toggle.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

Toggle.defaultProps = {
  value: true,
};

export { Toggle };
