import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import ReactCreatableSelect from "react-select/creatable";
import { colors } from "../../tailwind/colors";

const Select = forwardRef((props, ref) => {
  const { value, size, creatable, ...other } = props;

  const Component = creatable ? ReactCreatableSelect : ReactSelect;

  return (
    <Component
      defaultValue={value}
      value={value}
      {...other}
      styles={{
        multiValue: (provided, state) => {
          return {
            ...provided,
            background: colors.ui[100],
          };
        },
        multiValueRemove: (provided, state) => {
          return {
            ...provided,
            "&:hover": {
              background: colors.ui[200],
              color: colors.grey[600],
              cursor: "pointer",
            },
          };
        },
        control: (provided, state) => {
          return {
            ...provided,
            boxShadow: "none",
            "&:hover": {
              borderColor: state.isFocused ? colors.ui[400] : colors.ui[200],
            },
          };
        },
      }}
      theme={(theme) => {
        return {
          ...theme,
          borderRadius: 4,
          spacing: {
            ...theme.spacing,
            controlHeight: size === "medium" ? 50 : 42,
          },
          colors: {
            ...theme.colors,
            primary25: colors.ui[100],
            primary: colors.ui[400],
            neutral20: colors.ui[200],
            neutral30: colors.ui[300],
            neutral80: colors.grey[600],
          },
        };
      }}
      menuPosition="fixed"
    />
  );
});

Select.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.any.isRequireed,
      label: PropTypes.string,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any.isRequireed,
        label: PropTypes.string,
      })
    ),
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequireed,
      label: PropTypes.string,
    })
  ),
  size: PropTypes.oneOf(["small", "medium"]),
  creatable: PropTypes.bool,
  className: PropTypes.string,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

Select.defaultProps = {
  options: [],
  size: "medium",
  creatable: false,
  isMulti: false,
};

export { Select };
