import React from "react";

import { Route, Switch } from "react-router-dom";

import { useRoutes } from "version-one-dev-utils/routes";
import { useSelector } from "version-one-dev-utils/state";

import { Breadcrumbs } from "../../components/Breadcrumbs";

import { AdminProductionInfo } from "../AdminProductionInfo";
import { AdminProductionEditSuiteTable } from "../AdminProductionEditSuiteTable";
import { AdminProductionUserTable } from "../AdminProductionUserTable";

import { AddUsers } from "../AddUsers";
import { EditProduction } from "../EditProduction";
import { EditSuite } from "../EditSuite";

const DEFAULT_PRODUCTION = {
  name: "",
  description: "",
  tags: [],
  members: [],
  managers: [],
};

const AdminProduction = () => {
  const { routes, params, link } = useRoutes();

  const production = useSelector(
    (state) =>
      state.ProductionStore.find((production) => production.id === params.id) ||
      DEFAULT_PRODUCTION
  );

  return (
    <>
      <Breadcrumbs
        className="mb-6"
        items={[
          {
            name: "Productions",
            url: routes.ADMIN_PRODUCTIONS,
          },
          {
            name: production.name,
            url: link(routes.ADMIN_PRODUCTION, params),
          },
        ]}
      />

      <AdminProductionInfo />

      <h2 className="font-semibold text-lg mb-4 mt-10">Edit suites</h2>
      <AdminProductionEditSuiteTable />

      <h2 className="font-semibold text-lg mb-4 mt-10">Users</h2>
      <AdminProductionUserTable />

      <Switch>
        <Route path={routes.ADMIN_EDIT_PRODUCTION} component={EditProduction} />
        <Route path={routes.ADMIN_PRODUCTION_ADD_USERS} component={AddUsers} />

        <Route
          path={routes.ADMIN_PRODUCTION_EDIT_SUITE}
          component={EditSuite}
        />
      </Switch>
    </>
  );
};

export { AdminProduction };
