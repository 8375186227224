import React, { useMemo, forwardRef } from "react";
import PropTypes from "prop-types";

import { useSelector } from "version-one-dev-utils/state";
import { UserStore } from "../../stores/UserStore";

import { Select } from "../../components/Select";

const mapUser = (user) => ({
  value: user.id,
  label: `${user.fullName}  (${user.email})`,
});

const UserSelect = forwardRef((props, ref) => {
  const {
    value,
    users,
    filters,
    isMulti,
    isDisabled,
    onChange,
    ...otherProps
  } = props;

  const defaultUsers = useSelector((state) => state.UserStore);

  const data = useMemo(() => users || defaultUsers, [users, defaultUsers]);

  const options = useMemo(
    () =>
      data
        .filter(
          (user) =>
            !filters ||
            !Object.keys(filters).find((key) => user[key] !== filters[key])
        )
        .sort((a, b) => (a.fullName < b.fullName ? -1 : 1))
        .map(mapUser),

    [data, filters]
  );

  const defaultValue = useMemo(
    () =>
      isDisabled || !value
        ? null
        : isMulti
        ? value.map((user) => mapUser(UserStore.select.get(user)))
        : mapUser(UserStore.select.get(value)),
    [value, isMulti, isDisabled]
  );

  return (
    <Select
      {...otherProps}
      isMulti={isMulti}
      isDisabled={isDisabled}
      value={defaultValue}
      options={options}
      onChange={(value) => {
        if (isMulti) {
          onChange(value?.map((item) => item.value) || []);
        } else {
          onChange(value?.value || null);
        }
      }}
    />
  );
});

UserSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /** Override the default UserStore items before filters are applied.
   * Used to create a subset of production users to select from when assigning an edit suite.
   */
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    })
  ),
  filters: PropTypes.shape({
    role: PropTypes.oneOf(["admin", "operator"]),
    deleted: PropTypes.bool,
  }),
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export { UserSelect };
