import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Tag } from "../Tag";

import { ReactComponent as Icon } from "./icon.svg";

const ProductionInfo = (props) => {
  const { name, description, tags, isActive } = props;

  return (
    <div className={classnames(!isActive && "opacity-50")}>
      <div className="flex mb-6">
        <h3 className="text-2xl font-bold leading-none flex-1">{name}</h3>
        <Icon className={classnames(!isActive && "invisible")} />
      </div>
      <p className="leading-none mb-6">{description}</p>
      <div>
        {tags.map((tag, index) => (
          <Tag key={index} label={tag?.name || "..."} className="mr-2 mb-2" />
        ))}
      </div>
    </div>
  );
};

ProductionInfo.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  isActive: PropTypes.bool,
};

export { ProductionInfo };
