import React, { useMemo, forwardRef } from "react";
import PropTypes from "prop-types";

import { useController } from "react-hook-form";

import { useSelector } from "version-one-dev-utils/state";

import { ProductionTagStore } from "../../stores/ProductionTagStore";
import { EditSuiteTagStore } from "../../stores/EditSuiteTagStore";

import { Select } from "../../components/Select";
import { Tag } from "../../components/Tag";

const MultiValue = (props) => {
  return (
    <Tag
      label={props.children}
      className="mr-1"
      onDelete={props.removeProps.onClick}
    />
  );
};

MultiValue.propTypes = {
  children: PropTypes.string.isRequired,
  removeProps: PropTypes.shape({
    onClick: PropTypes.func,
  }).isRequired,
};

const stores = {
  production: ProductionTagStore,
  editSuite: EditSuiteTagStore,
};

const TagSelect = forwardRef((props, ref) => {
  const { name, type } = props;

  const store = useMemo(() => stores[type], [type]);

  const {
    field: { value = [], onChange, onBlur },
  } = useController({
    name,
  });

  const tags = useSelector((state) =>
    state[store.name]
      .map((tag) => ({
        value: tag.id,
        label: tag.name,
      }))
      .sort((a, b) => (a.label < b.label ? -1 : 1))
  );

  const defaultValue = useMemo(
    () =>
      value.map(({ id, name }) => {
        const tag = store.select.get(id);
        return { value: tag.id, label: name || tag.name };
      }),
    [value, store]
  );

  return (
    <Select
      creatable
      value={defaultValue}
      isMulti
      options={tags}
      components={{ MultiValue }}
      onChange={(value, ...args) => {
        onChange(
          value.map((v) => ({
            id: v.value,
            name: v.label,
            isNew: v.value === v.label,
          }))
        );
      }}
      onBlur={onBlur}
    />
  );
});

TagSelect.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["production", "editSuite"]).isRequired,
};

export { TagSelect };
