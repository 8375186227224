import React from "react";

import { useRoutes } from "version-one-dev-utils/routes";
import { useSelector } from "version-one-dev-utils/state";

import { EditSuiteTagStore } from "../../stores/EditSuiteTagStore";

import { Table } from "../../components/Table";
import { Tag } from "../../components/Tag";

import { UserCell } from "../UserCell";
import { EditSuiteCell } from "../EditSuiteCell";

const AdminProductionEditSuiteTable = () => {
  const { routes, push, params } = useRoutes();

  const editSuites = useSelector((state) =>
    state.EditSuiteStore.filter(
      (editSuite) => editSuite.production === params.id && !editSuite.deleted
    ).sort((a, b) => (a.name < b.name ? -1 : 1))
  );

  return (
    <Table
      columns={[
        {
          name: "Name",
          key: "name",
          minWidth: 200,
          render: (item) => <EditSuiteCell id={item.id} />,
        },
        {
          name: "Tags",
          key: "tags",
          minWidth: 250,
          render: (item) => {
            return (
              <div className="-mb-2">
                {item.tags.map((id, index) => {
                  const tag = EditSuiteTagStore.select.get(id);
                  return (
                    <Tag
                      key={index}
                      label={tag?.name || "..."}
                      className="mr-2 mb-2"
                    />
                  );
                })}
              </div>
            );
          },
        },
        {
          name: "Assigned to",
          key: "user",
          minWidth: 250,
          render: (item) => (item.user ? <UserCell id={item.user} /> : null),
        },
      ]}
      items={editSuites}
      addButton={{
        label: "New Edit Suite",
        onClick: () => {
          push(routes.ADMIN_PRODUCTION_EDIT_SUITE, {
            ...params,
            editSuite: "new",
          });
        },
      }}
      filter={(items, value) => {
        return items.filter((item) =>
          item.name.toLowerCase().includes(value.toLowerCase())
        );
      }}
      filterPlaceholder="Name"
      placeholder={`No edit suites`}
      onClickRow={(item) =>
        push(routes.ADMIN_PRODUCTION_EDIT_SUITE, {
          ...params,
          editSuite: item.id,
        })
      }
    />
  );
};

export { AdminProductionEditSuiteTable };
