import React from "react";

import { useRoutes } from "version-one-dev-utils/routes";
import { useSelector } from "version-one-dev-utils/state";

import { EditSuiteStore } from "../../stores/EditSuiteStore";

import { Button } from "../../components/Button";
import { CopyButton } from "../../components/CopyButton";
import { Input } from "../../components/Input";
import { Modal } from "../../components/Modal";

const OperatorEditSuite = () => {
  const { params, routes, push } = useRoutes();

  const editSuite = useSelector((state) =>
    state.EditSuiteStore.find((editSuite) => editSuite.id === params.editSuite)
  );

  return editSuite ? (
    <Modal
      title={editSuite.name}
      className="max-w-[700px]"
      onClose={() => push(routes.OPERATOR, params)}
    >
      <div className="text-center">
        <p className="mb-6">
          Copy your connection password and connect to your edit suite:
        </p>
        <div className="w-2/3 min-w-[300px] max-w-[400px] mx-auto mb-6">
          <div className="relative inline-block w-full">
            <Input
              value={editSuite.connection?.password}
              readOnly
              className="mb-8 text-2xl font-bold pr-[100px] w-full"
              data-private
            />
            <div className="absolute" style={{ top: 10, right: 15 }}>
              <CopyButton value={editSuite.connection.password} />
            </div>
          </div>
          <Button
            label="Connect to Edit Suite"
            className="w-full"
            color="primary"
            size="large"
            onClick={() => EditSuiteStore.actions.connect(editSuite)}
          />
        </div>
        <div className="p-6 bg-ui-100 rounded-md">
          <h4 className="mb-4">Install Teradici</h4>
          <p className="text-sm">
            Please make sure you have installed and configured Teradici before
            continuing. See the{" "}
            <a
              href="https://docs.teradici.com/find/product/software-and-mobile-clients"
              target="_blank"
              rel="noreferrer"
              className="font-semibold text-violet-500 underline hover:no-underline"
            >
              Teradici Installation Guide
            </a>{" "}
            for more information.
          </p>
        </div>
      </div>
    </Modal>
  ) : null;
};

export { OperatorEditSuite };
