import React, { useEffect, useRef, useState } from "react";

import { AuthStore } from "../../stores/AuthStore";

//import { useSelector } from "version-one-dev-utils/state";

import { Button } from "../../components/Button";
import { Modal } from "../../components/Modal";

function termly(d, s, id) {
  var js,
    tjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s);
  js.id = id;
  js.src = "https://app.termly.io/embed-policy.min.js";
  tjs.parentNode.insertBefore(js, tjs);

  return () => js.remove();
}

const OperatorTerms = (props) => {
  const ref = useRef();
  const [hasRead, setHasRead] = useState(false);

  useEffect(() => {
    return termly(document, "script", "termly-jssdk");
  }, []);

  useEffect(() => {
    if (!hasRead) {
      const parent = ref.current;

      const onScroll = (e) => {
        if (
          parent.scrollHeight - (e.target.scrollTop + e.target.offsetHeight) <
          50
        ) {
          setHasRead(true);
        }
      };

      parent.addEventListener("scroll", onScroll);
      return () => parent.removeEventListener("scroll", onScroll);
    }
  }, [hasRead]);

  return (
    <Modal>
      <div className="relative overflow-scroll h-[70vh]">
        {
          <div
            ref={ref}
            name="termly-embed"
            data-id={process.env.REACT_APP_TERMLY_API_KEY}
            data-type="iframe"
            style={{
              height: "calc(100% - 107px)",
              overflow: "scroll",
            }}
          />
        }

        <div className="text-center mt-8 pt-8 bg-white bg-opacity-80 border-t border-t-ui-100">
          <Button
            label="Accept Terms and Conditions"
            disabled={!hasRead}
            color={!hasRead ? "default" : "primary"}
            onClick={AuthStore.actions.agreeTerms}
          />
        </div>
      </div>
    </Modal>
  );
};

export { OperatorTerms };
