import React from "react";

import styles from "./index.module.css";

const Loader = () => {
  return <span className={styles.spinner} />;
};

Loader.propTypes = {};

export { Loader };
