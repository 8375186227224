import React, { useState } from "react";
import PropTypes from "prop-types";

import { Button } from "../Button";

function Invite({ name, production, onAccept, onDecline }) {
  const [pending, setPending] = useState();

  return (
    <div className="flex items-center bg-ui-100 border border-ui-200 p-8 rounded-lg">
      <span className="flex-1 mr-16">
        {name} has invited you to {production}
      </span>
      <Button
        label="Accept"
        onClick={() => {
          setPending("accept");
          Promise.resolve(onAccept()).then(() => setPending(null));
        }}
        color="primary"
        size="small"
        className="mr-2"
        pending={pending === "accept"}
      />
      <Button
        label="Decline"
        onClick={() => {
          setPending("decline");
          Promise.resolve(onDecline()).then(() => setPending(null));
        }}
        color="default"
        size="small"
        pending={pending === "decline"}
      />
    </div>
  );
}

Invite.propTypes = {
  name: PropTypes.string.isRequired,
  production: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
};

export { Invite };
