import React from "react";
import { Route } from "react-router-dom";

import { useRoutes } from "version-one-dev-utils/routes";
import { useSelector } from "version-one-dev-utils/state";

import { ProductionTagStore } from "../../stores/ProductionTagStore";

import { Table } from "../../components/Table";
import { Tag } from "../../components/Tag";
import { InfoCell } from "../../components/InfoCell";

import { EditProduction } from "../EditProduction";

const AdminProductions = () => {
  const { routes, push } = useRoutes();

  const { productions } = useSelector((state) => ({
    productions: state.ProductionStore.filter(
      (production) => !production.deleted
    ).sort((a, b) => (a.name < b.name ? -1 : 1)),
  }));

  return (
    <>
      <Table
        columns={[
          {
            name: "Name",
            key: "name",
            minWidth: 200,
            render: (item) => (
              <InfoCell name={item.name} description={item.id} />
            ),
          },
          {
            name: "Description",
            key: "description",
            minWidth: 300,
          },
          {
            name: "Tags",
            key: "tags",
            minWidth: 250,
            render: (item) => {
              return (
                <div className="-mb-2">
                  {item.tags.map((id, index) => {
                    const tag = ProductionTagStore.select.get(id);
                    return (
                      <Tag
                        key={index}
                        label={tag?.name || "..."}
                        className="mr-2 mb-2"
                      />
                    );
                  })}
                </div>
              );
            },
          },
        ]}
        items={productions}
        addButton={{
          label: "New Production",
          onClick: () => {
            push(routes.ADMIN_NEW_PRODUCTION);
          },
        }}
        filter={(items, value) => {
          return items.filter((item) =>
            item.name.toLowerCase().includes(value.toLowerCase())
          );
        }}
        filterPlaceholder="Name"
        placeholder={`No productions`}
        onClickRow={(item) => push(routes.ADMIN_PRODUCTION, item)}
      />
      <Route path={routes.ADMIN_NEW_PRODUCTION} component={EditProduction} />
    </>
  );
};

export { AdminProductions };
