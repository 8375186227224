module.exports = {
  colors: {
    transparent: "transparent",
    black: "#000",
    white: "#fff",
    purple: {
      500: "#7209B7",
    },
    violet: {
      300: "#9986F8",
      400: "#6062D2",
      500: "#4043CF",
    },
    pink: {
      200: "#ffc6e5",
      500: "#F72B94",
      600: "#E60778",
    },

    blue: {
      500: "#4CC9F0",
      600: "#4895EF",
    },
    green: {
      500: "#26DF7C",
    },
    ui: {
      50: "#FBFCFF",
      100: "#F1F1FA",
      200: "#E5E7F5",
      300: "#CBCEEB",
      400: "#8990C3",
      500: "#4A529B",
      600: "#292E58",
      700: "#0B112D",
      800: "#070814",
    },
    grey: {
      50: "#F5F7FA",
      100: "#EDEFF4",
      200: "#D6D8E0",
      300: "#BDC1CC",
      400: "#8E93A5",
      500: "#5F657A",
      600: "#2F364A",
      700: "#212633",
      800: "#10121A",
      900: "#07080C",
    },
    background: "#020214",
    font: "#C2C6DB",
    border: "#303460",
    placeholder: "#5f657a",
  },
};
