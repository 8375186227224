import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Loader } from "../Loader";

import styles from "./index.module.css";

const Button = (props) => {
  const {
    label,
    size,
    color,
    type,
    pending,
    disabled,
    className,
    icon,
    onClick,
  } = props;

  const Icon = icon;

  return (
    <button
      className={classnames(
        "relative border font-bold inline-flex items-center justify-center rounded-full focus:outline-none overflow:hidden",
        size === "xsmall" && "text-xs py-1 px-2",
        size === "small" && "text-xs py-2 px-8",
        size === "medium" && "text-sm px-8 h-[42px]",
        size === "large" && "text-sm py-5 px-8",
        color === "default" &&
          "bg-white border-ui-200 hover:border-ui-300 text-ui-700",
        color === "primary" &&
          `border-transparent text-white ${styles.primary}`,
        color === "danger" &&
          "border-transparent bg-pink-500 hover:bg-pink-600 text-white",
        (pending || disabled) && "pointer-events-none",
        disabled && "opacity-50 text-opacity-50",
        className
      )}
      disabled={disabled}
      type={type}
      onClick={onClick}
    >
      {Icon && <Icon className="mr-2" />}
      <span className={classnames("leading-none", pending && "opacity-10")}>
        {label}
      </span>
      {pending && (
        <div
          className={classnames(
            size === "xsmall" && "text-xxs",
            size === "small" && "text-xxs",
            size === "medium" && "text-sm ",
            size === "large" && "text-sm "
          )}
        >
          <Loader />
        </div>
      )}
    </button>
  );
};

Button.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(["xsmall", "small", "medium", "large"]),
  color: PropTypes.oneOf(["default", "primary", "danger"]),
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  icon: PropTypes.elementType,
  pending: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  size: "medium",
  color: "default",
  type: "submit",
  pending: false,
};

export { Button };
