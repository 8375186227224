import React, { useEffect, useMemo, useState } from "react";
import classnames from "classnames";

import { Route, Link } from "react-router-dom";
import { useRoutes } from "version-one-dev-utils/routes";
import { usePending } from "version-one-dev-utils/state";

import { UserStore } from "../../stores/UserStore";
import { EditSuiteTagStore } from "../../stores/EditSuiteTagStore";
import { ProductionTagStore } from "../../stores/ProductionTagStore";
import { ProductionStore } from "../../stores/ProductionStore";
import { EditSuiteStore } from "../../stores/EditSuiteStore";

import { Loader } from "../../components/Loader";

import { Header } from "../Header";
import { Switch } from "../Switch";
import { AdminProduction } from "../AdminProduction";
import { AdminProductions } from "../AdminProductions";
import { AdminUsers } from "../AdminUsers";
import { AuditLog } from "../AuditLog";

import { ReactComponent as Icon } from "./icon.svg";

const AdminDashboard = () => {
  const { path, routes, link } = useRoutes();

  const { getPending } = usePending();

  useEffect(() => {
    UserStore.actions.watch();
    EditSuiteTagStore.actions.watch();
    ProductionTagStore.actions.watch();
    EditSuiteStore.actions.watch({});
    ProductionStore.actions.watch({});
  }, []);

  const [pending, setPending] = useState(true);

  useEffect(() => {
    const value = !!getPending(
      UserStore.actions.watch,
      EditSuiteTagStore.actions.watch,
      ProductionTagStore.actions.watch,
      EditSuiteStore.actions.watch,
      ProductionStore.actions.watch
    );

    if (value !== pending) {
      const timeout = setTimeout(() => setPending(value), 200);
      return () => clearTimeout(timeout);
    }
  }, [getPending, setPending, pending]);

  const pages = useMemo(
    () => [
      {
        name: "Production",
        path: routes.ADMIN_PRODUCTION,
        component: AdminProduction,
        navbar: "hidden",
      },
      {
        name: "Productions",
        path: routes.ADMIN_PRODUCTIONS,
        component: AdminProductions,
        navbar: "default",
      },
      {
        name: "Users",
        path: routes.ADMIN_OPERATORS,
        component: AdminUsers,
        navbar: "default",
      },
      {
        name: "Admins",
        path: routes.ADMIN_ADMINS,
        component: AdminUsers,
        navbar: "default",
      },
      {
        name: "Audit Log",
        path: routes.ADMIN_LOG,
        component: AuditLog,
        navbar: "small",
      },
    ],
    [routes]
  );

  const currentPage = useMemo(() => {
    return pages.find((page) => path.startsWith(page.path));
  }, [pages, path]);

  return (
    <>
      <Header />
      {pending && (
        <div className="text-pink-500 text-3xl">
          <Loader />
        </div>
      )}
      {!pending && (
        <div className="flex p-8">
          <div className="mr-16">
            <nav>
              <ul>
                {pages
                  .filter((page) => page.navbar !== "hidden")
                  .map((page) => {
                    const active = page === currentPage;

                    return (
                      <li key={page.name} className="mb-6">
                        <Link
                          to={link(page.path)}
                          className={classnames(
                            "flex items-center font-semibold hover:text-violet-500",
                            page.navbar === "default" && "text-xl",
                            page.navbar === "small" && "text-sm",
                            active && "text-violet-500"
                          )}
                        >
                          <Icon
                            className={classnames(
                              "inline-block mr-2 transition-opacity duration-300",
                              !active && "opacity-0"
                            )}
                          />
                          <span
                            className={classnames(
                              !active && "transform translate-x-[-20px]",
                              "transition-transform duration-300"
                            )}
                          >
                            {page.name}
                          </span>
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </nav>
          </div>
          <div className="flex-1">
            <Switch>
              {pages.map((page) => (
                <Route key={page.path} {...page} />
              ))}
            </Switch>
          </div>
        </div>
      )}
    </>
  );
};

export { AdminDashboard };
