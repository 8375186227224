import React, { useState } from "react";

import classnames from "classnames";

import { useRoutes } from "version-one-dev-utils/routes";
import { useSelector, usePending } from "version-one-dev-utils/state";

import { ProductionStore } from "../../stores/ProductionStore";
import { InviteStore } from "../../stores/InviteStore";

import { Button } from "../../components/Button";
import { Select } from "../../components/Select";
import { Table } from "../../components/Table";
import { ConfirmationModal } from "../../components/ConfirmationModal";

const roles = [
  { value: "MEMBER", label: "Member" },
  { value: "MANAGER", label: "Production Manager" },
];

const OperatorProductionUserTable = () => {
  const { routes, push, params } = useRoutes();

  const { getPending } = usePending();

  const production = useSelector(
    (state) =>
      state.ProductionStore.find(
        (production) =>
          production.id === params.productionId && !production.deleted
      ) || ProductionStore.select.get(params.productionId)
  );

  const users = useSelector((state) =>
    state.UserStore.filter(
      (user) => production.members.includes(user.id) && !user.deleted
    )
      .map((user) => {
        const role = production.managers.includes(user.id)
          ? "MANAGER"
          : "MEMBER";

        const invite = state.InviteStore.production.find(
          (i) => i.to === user.id
        );
        return { ...user, role, inviteId: invite?.id };
      })
      .sort((a, b) => (a.fullName < b.fullName ? -1 : 1))
      .sort((a, b) =>
        Boolean(a.inviteId) !== Boolean(b.inviteId) && !a.inviteId ? -1 : 1
      )
  );

  const authUser = useSelector((state) => state.AuthStore);

  const [isDeletingUser, setIsDeletingUser] = useState(null);

  return (
    <>
      <Table
        columns={[
          {
            name: "Name",
            key: "fullName",
            render: (item) => (
              <span
                className={classnames(item.inviteId && "italic opacity-50")}
              >
                {[item.fullName, item.inviteId ? "Invited" : null]
                  .filter((v) => v)
                  .join(" - ")}
              </span>
            ),
          },
          {
            name: "Email",
            key: "email",
            render: (item) => (
              <span
                className={classnames(item.inviteId && "italic opacity-50")}
              >
                {item.email}
              </span>
            ),
          },
          {
            name: "Role",
            key: "role",
            minWidth: 180,
            render: (item) => (
              <Select
                size="small"
                value={roles.find((role) => role.value === item.role)}
                options={roles}
                isDisabled={
                  item.role === "MANAGER" && production.managers.length <= 1
                }
                onChange={(change) =>
                  ProductionStore.actions.setUserRole({
                    id: production.id,
                    userId: item.id,
                    role: change.value,
                  })
                }
              />
            ),
          },
          {
            name: "",
            key: "",
            minWidth: 171,
            render: (item) => (
              <div className="flex justify-end">
                {item.inviteId && (
                  <Button
                    color="primary"
                    label="Resend Invite"
                    size="xsmall"
                    className="mr-2"
                    pending={
                      !!getPending(
                        InviteStore.actions.invite.byKey(item.inviteId)
                      )
                    }
                    onClick={() =>
                      InviteStore.actions.invite(
                        {
                          production: production.id,
                          emails: [item.email],
                        },
                        item.inviteId
                      )
                    }
                  />
                )}
                {item.id !== authUser.id && (
                  <Button
                    label="Remove"
                    size="xsmall"
                    onClick={() => setIsDeletingUser(item)}
                  />
                )}
              </div>
            ),
          },
        ]}
        items={users}
        addButton={{
          label: `Invite Users`,
          onClick: () => {
            push(routes.OPERATOR_INVITE, params);
          },
        }}
        filter={(items, value) => {
          return items.filter(
            (item) =>
              item.fullName.toLowerCase().includes(value.toLowerCase()) ||
              item.email.toLowerCase().includes(value.toLowerCase())
          );
        }}
        filterPlaceholder="Name or email"
        placeholder={`No users`}
        isRowPending={(item) => item.inviteId}
      />

      {isDeletingUser && (
        <ConfirmationModal
          title={`Remove ${isDeletingUser.fullName} from ${production.name}`}
          confirm="Yes"
          dismiss="No"
          onConfirm={() => {
            return ProductionStore.actions
              .removeUser(
                { id: production.id, userId: isDeletingUser.id },
                isDeletingUser.id
              )
              .then(() => {
                return isDeletingUser.inviteId
                  ? InviteStore.actions.remove(
                      { id: isDeletingUser.inviteId },
                      isDeletingUser.id
                    )
                  : Promise.resolve();
              })
              .then(() => setIsDeletingUser(null));
          }}
          onClose={() => setIsDeletingUser(null)}
        />
      )}
    </>
  );
};

export { OperatorProductionUserTable };
