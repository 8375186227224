import React, { useEffect, useState } from "react";

import { useRoutes } from "version-one-dev-utils/routes";
import { useSelector } from "version-one-dev-utils/state";

import { Link } from "react-router-dom";

import { ProductionTagStore } from "../../stores/ProductionTagStore";
import { ProductionStore } from "../../stores/ProductionStore";

import { Field } from "../../components/Field";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import { Modal } from "../../components/Modal";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { TagSelect } from "../TagSelect";

import { useForm } from "../../deps/useForm";
import { Form } from "../../deps/Form";
import { string, array } from "yup";

const DEFAULT_PRODUCTION = {
  name: "",
  description: "",
  tags: [],
  members: [],
  managers: [],
};

const EditProduction = (props) => {
  const { params, routes, link, push } = useRoutes();

  const [isDeleting, setIsDeleting] = useState(false);

  const production = useSelector(
    (state) =>
      state.ProductionStore.find((production) => production.id === params.id) ||
      DEFAULT_PRODUCTION
  );

  const isEditing = !!production.id;

  const form = useForm({
    schema: {
      name: string().required("Please enter a name"),
      description: string().required("Please enter a description"),
      tags: array(),
    },
    onSubmit: (values) => {
      return Promise.all(
        // Add new tags
        values.tags
          .filter((tag) => tag.isNew)
          .map(ProductionTagStore.actions.add)
      )
        .then((actions) => {
          const newTags = actions.map((action) => action.payload);

          const newProduction = {
            ...production,
            ...values,
            // Update tags and convert back to ids
            tags: values.tags.map((tag) => {
              return tag.isNew
                ? newTags.find((newTag) => newTag.name === tag.name)?.id
                : tag.id;
            }),
          };

          // Save the production
          return isEditing
            ? ProductionStore.actions.edit(newProduction, production.id)
            : ProductionStore.actions.add(newProduction);
        })
        .then(close);
    },
  });

  const {
    register,
    reset,
    formState: { errors, isSubmitting },
  } = form;

  /**
   * Async default values
   */
  useEffect(() => {
    reset({
      ...production,
      // Convert tags to objects
      tags: production.tags.map((tag) => ({ id: tag })),
    });
  }, [production, reset]);

  const close = () =>
    push(
      isEditing ? routes.ADMIN_PRODUCTION : routes.ADMIN_PRODUCTIONS,
      params
    );

  return isDeleting ? (
    <ConfirmationModal
      title={`Delete ${production.name} from productions?`}
      confirm="Yes"
      dismiss="No"
      onConfirm={() =>
        ProductionStore.actions
          .remove(production, production.id)
          .then(() => push(routes.ADMIN_PRODUCTIONS))
      }
      onClose={() => setIsDeleting(false)}
    />
  ) : (
    <Modal title={`${isEditing ? "Edit" : "New"} Production`} onClose={close}>
      <Form config={form}>
        <Field className="mb-10" label="Name" error={errors.name}>
          <Input {...register("name")} />
        </Field>

        <Field className="mb-10" label="Description" error={errors.description}>
          <Input {...register("description")} />
        </Field>

        <Field className="mb-10" label="Tags" error={errors.tags}>
          <TagSelect {...register("tags")} type="production" />
        </Field>

        {isEditing && (
          <Button
            className="mt-5"
            type="button"
            label="Delete Production"
            onClick={() => setIsDeleting(true)}
          />
        )}

        <div className="flex items-center mt-16">
          <div className="flex-1 mr-8">
            <Link
              className="font-semibold underline hover:no-underline"
              to={link(
                isEditing ? routes.ADMIN_PRODUCTION : routes.ADMIN_PRODUCTIONS,
                params
              )}
            >
              Cancel
            </Link>
          </div>
          <Button
            label="Save Production"
            color="primary"
            pending={isSubmitting}
          />
        </div>
      </Form>
    </Modal>
  );
};

export { EditProduction };
