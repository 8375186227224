import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Button } from "../Button";
import { Tag } from "../Tag";

import Placeholder1 from "./placeholder1.png";
import Placeholder2 from "./placeholder2.png";
import Placeholder3 from "./placeholder3.png";

export const Placeholders = [
  { src: Placeholder1, color: "#151255" },
  { src: Placeholder2, color: "#350247" },
  { src: Placeholder3, color: "#4E0024" },
];

const getPlaceholder = (name) => {
  let sum = 0;

  for (let i = 0; i < name.length; i++) {
    sum += name.charCodeAt(i);
  }

  return Placeholders[sum % Placeholders.length];
};

const EditSuite = (props) => {
  const { name, tags, assignedTo, className } = props;

  const placeholder = getPlaceholder(name);

  return (
    <div
      className={classnames(
        "relative rounded-3xl overflow-hidden p-6",
        className
      )}
      style={{
        background: `linear-gradient(180deg, ${placeholder.color} 0%, #0f0f0f 75%)`,
      }}
    >
      <div
        className={classnames("flex items-center", assignedTo && "opacity-50")}
      >
        <img
          src={placeholder.src}
          alt={name}
          className="mr-6 rounded-2xl overflow-hidden border border-white border-opacity-10 shadow"
          style={{
            width: 100,
            height: 100,
          }}
        />
        <div className="flex-1">
          <h2 className="font-bold text-2xl mb-4 text-white">{name}</h2>
          <div>
            {tags.map((tag, index) => (
              <Tag
                key={index}
                label={tag.name}
                type="dark"
                className="mr-2 mb-2"
              />
            ))}
          </div>
        </div>
        {!assignedTo && (
          <Button
            label="Connect"
            color="primary"
            className="w-[150px] shadow"
          />
        )}
        {assignedTo && (
          <span className="text-white">
            Assigned to <span className="italic">{assignedTo}</span>
          </span>
        )}
      </div>
    </div>
  );
};

EditSuite.propTypes = {
  name: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  assignedTo: PropTypes.string,
  className: PropTypes.string,
};

EditSuite.defaultProps = {};

export { EditSuite };
