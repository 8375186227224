import React from "react";
import classnames from "classnames";

import { Link } from "react-router-dom";

import { useRoutes } from "version-one-dev-utils/routes";
import { useErrors } from "version-one-dev-utils/state";

import { AuthStore } from "../../stores/AuthStore";

import { Form } from "../../deps/Form";
import { useForm } from "../../deps/useForm";
import { string } from "yup";

import { Field } from "../../components/Field";
import { InputTwo } from "../../components/InputTwo";
import { PasswordInputTwo } from "../../components/PasswordInputTwo";
import { Button } from "../../components/Button";
import { Footer } from "../Footer";

import { ReactComponent as Logo } from "./logo.svg";
import BackgroundImage from "../../images/bg-copy.jpg";

import styles from "./index.module.css";

const Login = () => {
  const { routes, link } = useRoutes();

  const { getError } = useErrors();
  const error = getError(AuthStore.actions.login);

  const form = useForm({
    schema: {
      email: string()
        .required("Please enter your email address")
        .email("Invalid email address"),
      password: string().required("Please enter your password"),
    },
    onSubmit: AuthStore.actions.login,
  });

  const {
    register,
    formState: { errors, isSubmitting },
  } = form;

  return (
    <div className="h-screen flex flex-col">
      <div className="bg-black flex flex-col items-center justify-between h-full">
        <header className="relative w-full px-8 pt-8 z-10">
          <Logo />
        </header>
        <div
          className="bg-cover bg-center	opacity-60 h-full w-screen absolute"
          style={{
            backgroundImage: `url(${BackgroundImage})`,
          }}
        />
        <div className="relative flex-col items-center justify-center">
          <h2 className="text-[32px] font-bold text-center text-white pb-6">
            Log in to your Racoon account
          </h2>
          <div className="flex-1 flex items-center relative z-10">
            <Form config={form} className="w-[500px]">
              {error && (
                <div className="text-pink-500 text-center font-semibold mb-8">
                  {error.value.message}
                </div>
              )}
              <Field
                className="mb-10"
                labelColor="white"
                error={errors.email}
                errorMessageSize="large"
                autoComplete="email"
              >
                <InputTwo
                  {...register("email")}
                  size="l"
                  placeholder="Email address"
                  className={classnames(
                    styles.inputStyle,
                    styles.placeholderStyle
                  )}
                  autoComplete="email"
                />
              </Field>
              <Field
                className="mb-10"
                labelColor="white"
                error={errors.password}
                errorMessageSize="large"
              >
                <PasswordInputTwo
                  {...register("password")}
                  type="password"
                  size="l"
                  autoComplete="current-password"
                />
              </Field>
              <Button
                label="Login"
                color="primary"
                size="large"
                pending={isSubmitting}
                className="w-full rounded-[8px] text-lg"
              />
              <div className="mt-5 text-center">
                <Link
                  className="underline hover:no-underline"
                  style={{ color: "#C2C6DB" }}
                  to={link(routes.RESET_PASSWORD)}
                >
                  Forgot password?
                </Link>
              </div>
              <div className="flex flex-row justify-center text-xs mt-4 mb-4">
                <div className="text-[16px]" style={{ color: "#C2C6DB" }}>
                  Don&apos;t have an account?
                </div>
                <Link
                  to={link(routes.REGISTER)}
                  className="ml-1 text-blue-600 font-bold underline text-[16px]"
                >
                  Register your interest
                </Link>
              </div>
            </Form>
          </div>
        </div>
        <div className="w-full">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export { Login };
