import React from "react";

import { ReactComponent as Logo } from "./logo.svg";

import BackgroundImage from "../../images/bg-new.jpg";

const FourOhFour = () => {
  return (
    <div
      className="flex p-8 bg-cover flex items-center justify-center text-white"
      style={{ backgroundImage: `url(${BackgroundImage})`, minHeight: "100vh" }}
    >
      <h1
        style={{
          lineHeight: 0,
          fontSize: "calc(100vw/3)",
          mixBlendMode: "soft-light",
          opacity: 0.5,
          color: "#58ffe9",
          fontWeight: "bold",
        }}
      >
        404
      </h1>
      <div className="absolute m-auto">
        <Logo />
        <h2 className="text-xl text-center mt-10 font-semibold">
          Page not found
        </h2>
      </div>
    </div>
  );
};

export { FourOhFour };
