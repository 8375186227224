import { createStore } from "version-one-dev-utils/state";
import PropTypes from "prop-types";

import { Firebase, firebase } from "../Firebase";

import { AuthStore } from "./AuthStore";

export const roles = {
  admin: {
    label: "Admin",
  },
  operator: {
    label: "User",
  },
};

let unsubscribe;

const watch = () => (resolve, reject) => {
  unsubscribe && unsubscribe();

  unsubscribe = Firebase.firestore()
    .collection("users")
    .onSnapshot((querySnapshot) => {
      const users = [];

      querySnapshot.forEach((doc) => {
        users.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      resolve(users);
    }, reject);
};

watch.success = (state, action) =>
  action.payload.map((user) => ({
    ...user,
    dateOfBirth: user.dateOfBirth.toMillis(),
    fullName: `${user.firstName} ${user.lastNames}`,
  }));

const add = (props) => {
  const user = {
    firstName: props.firstName,
    lastNames: props.lastNames,
    dateOfBirth: props.dateOfBirth,
    email: props.email,
    role: props.role,
    company: props.company,
    modifiedBy: AuthStore.getState().id,
    appHostname: process.env.REACT_APP_HOSTNAME,
  };

  return fetch(
    `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/createUser`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return Promise.resolve(props.email);
      } else {
        return res.json().then((resJson) => {
          return Promise.reject(resJson.error);
        });
      }
    })
    .catch((error) => {
      console.error("Error", String(error));
      return Promise.reject(new Error(String(error)));
    });
};

add.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastNames: PropTypes.string.isRequired,
  dateOfBirth: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  role: PropTypes.oneOf(["admin", "operator"]).isRequired,
  company: PropTypes.string.isRequired,
};

const edit = (props) => {
  const id = props.id;

  const user = {
    firstName: props.firstName,
    lastNames: props.lastNames,
    dateOfBirth: firebase.firestore.Timestamp.fromMillis(props.dateOfBirth),
    company: props.company,
    modifiedBy: AuthStore.getState().id,
  };

  return Firebase.firestore()
    .collection("users")
    .doc(id)
    .update(user)
    .catch((error) => {
      console.error("Error", String(error));
      return Promise.reject(new Error(String(error)));
    });
};

edit.propTypes = {
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastNames: PropTypes.string,
  dateOfBirth: PropTypes.number.isRequired,
  company: PropTypes.string.isRequired,
};

const remove = (props) => {
  const id = props.id;

  const user = {
    modifiedBy: AuthStore.getState().id,
    deleted: true,
  };

  delete user.id;

  return Firebase.firestore()
    .collection("users")
    .doc(id)
    .update(user)
    .catch((error) => {
      console.error("Error", String(error));
      return Promise.reject(new Error(String(error)));
    });
};

remove.propTypes = {
  id: PropTypes.string.isRequired,
};

export const UserStore = createStore({
  name: "UserStore",
  initialState: [],
  actions: { watch, add, edit, remove },
  propTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastNames: PropTypes.string,
      fullName: PropTypes.string,
      role: PropTypes.oneOf(["admin", "operator"]),
      company: PropTypes.string,
      deleted: PropTypes.bool,
    })
  ),
});

UserStore.select = {
  get: (id) =>
    UserStore.getState().find((v) => v.id === id) || {
      id,
      email: "Missing User",
      firstName: "Missing",
      lastNames: "User",
      fullName: "Missing User",
      dateOfBirth: 0,
      role: "operator",
      company: "",
      deleted: false,
    },
};
