import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useRoutes } from "version-one-dev-utils/routes";
import { useErrors } from "version-one-dev-utils/state";

import { AuthStore } from "../../stores/AuthStore";

import { Form } from "../../deps/Form";
import { useForm } from "../../deps/useForm";
import { string } from "yup";
import { AccessPage } from "../../components/AccessPage";
import { Field } from "../../components/Field";
import { PasswordInput } from "../../components/PasswordInput";
import { Button } from "../../components/Button";

const SetPassword = () => {
  const { routes, params } = useRoutes();

  const [done, setDone] = useState(false);
  const [matchPasswordsError, setMatchPasswordsError] = useState(false);

  const { getError } = useErrors();
  const error = getError(AuthStore.actions.setPassword);

  const form = useForm({
    schema: {
      password: string().min(2, "Password must contain at least 2 characters"),
      confirmPassword: string().min(
        2,
        "Password must contain at least 2 characters"
      ),
      code: string(),
    },
    defaultValues: params,
    onSubmit: (value) => {
      setMatchPasswordsError(false);
      if (value.password !== value.confirmPassword) {
        return setMatchPasswordsError(true);
      }
      return AuthStore.actions.setPassword(value).then(() => setDone(true));
    },
  });

  const {
    register,
    formState: { errors, isSubmitting },
  } = form;

  return (
    <AccessPage>
      {!done && (
        <Form config={form} className="w-[400px]">
          <h1 className="text-3xl font-semibold mb-10 text-center text-white">
            Set a password
          </h1>
          {matchPasswordsError && (
            <div className="text-pink-500 text-center font-semibold mb-8">
              Passwords must match
            </div>
          )}
          {error && (
            <div className="text-pink-500 text-center font-semibold mb-8">
              {error.value.message}
            </div>
          )}
          <Field
            className="mb-8"
            label="Password"
            labelColor="white"
            error={errors.password}
            errorMessageSize="large"
          >
            <PasswordInput
              {...register("password")}
              type="password"
              size="large"
              autoComplete="new-password"
            />
          </Field>
          <Field
            className="mb-12"
            label="Confirm password"
            labelColor="white"
            size="large"
            error={errors.confirmPassword}
            errorMessageSize="large"
          >
            <PasswordInput
              {...register("confirmPassword")}
              type="password"
              size="large"
              autoComplete="new-password"
            />
          </Field>
          <Button
            label="Save Password"
            color="primary"
            size="large"
            pending={isSubmitting}
            className="w-full"
          />
        </Form>
      )}
      {done && (
        <div className="font-semibold text-2xl text-center text-white">
          <p className="mb-8">Your password has been set</p>
          <p>
            Please{" "}
            <Link
              to={routes.LOGIN}
              className="text-pink-500 underline hover:no-underline"
            >
              login
            </Link>{" "}
            to continue
          </p>
        </div>
      )}
    </AccessPage>
  );
};

export { SetPassword };
