import React from "react";

import { useRoutes } from "version-one-dev-utils/routes";
import { useSelector } from "version-one-dev-utils/state";

import { ProductionStore } from "../../stores/ProductionStore";
import { ProductionTagStore } from "../../stores/ProductionTagStore";

import { Button } from "../../components/Button";
import { Tag } from "../../components/Tag";

const AdminProductionInfo = () => {
  const { routes, push, params } = useRoutes();

  const production = useSelector((state) =>
    ProductionStore.select.get(params.id)
  );

  return (
    <div className="p-4 border border-ui-200 bg-white rounded-lg">
      <div className="flex mb-4">
        <h1 className="font-semibold text-2xl flex-1 self-center">
          {production.name}
        </h1>
        <Button
          size="small"
          label="Edit"
          color="primary"
          className="self-start"
          onClick={() => push(routes.ADMIN_EDIT_PRODUCTION, params)}
        />
      </div>
      <p className="mb-6">{production.description}</p>
      <div className="mb-2">
        {production.tags.map((id, index) => {
          const tag = ProductionTagStore.select.get(id);
          return (
            <Tag key={index} label={tag?.name || "..."} className="mr-2 mb-2" />
          );
        })}
      </div>
    </div>
  );
};

export { AdminProductionInfo };
