import React from "react";
import PropTypes from "prop-types";

import { Route, Redirect } from "react-router-dom";

import { useRoutes } from "version-one-dev-utils/routes";
import { useSelector } from "version-one-dev-utils/state";

import { AccountDeleted } from "../AccountDeleted";

const AuthRoute = (props) => {
  const { role, children, component, render, ...routeProps } = props;
  const { routes, link } = useRoutes();

  const { authRole, deleted } = useSelector((state) => ({
    authRole: state.AuthStore.role,
    deleted: state.AuthStore.deleted,
  }));

  if (deleted) {
    return <AccountDeleted />;
  }

  return (
    <Route
      {...routeProps}
      render={() => {
        if (authRole !== role) {
          switch (authRole) {
            case "admin":
              return <Redirect to={link(routes.ADMIN_PRODUCTIONS)} />;
            case "operator":
              return <Redirect to={link(routes.OPERATOR)} />;
            default:
              return <Redirect to={link(routes.LOGIN)} />;
          }
        }

        const Component = component;
        const renderedComp = Component && <Component />;

        return children || renderedComp || (render && render());
      }}
    />
  );
};

AuthRoute.propTypes = {
  children: PropTypes.any,
  component: PropTypes.any,
  render: PropTypes.func,
  exact: PropTypes.bool,
  path: PropTypes.string,
  role: PropTypes.oneOf(["admin", "operator"]),
};

export { AuthRoute };
