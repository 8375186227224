import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Link } from "react-router-dom";

import { ReactComponent as Arrow } from "./arrow.svg";

const Breadcrumbs = (props) => {
  const { items, className } = props;

  return (
    <ul className={classnames("font-semibold", className)}>
      {items.map((item, index) => (
        <li key={index} className="inline-block">
          {index !== items.length - 1 ? (
            <>
              <Link to={item.url} className="underline hover:no-underline">
                {item.name}
              </Link>
              <Arrow className="inline-block mx-4" />
            </>
          ) : (
            item.name
          )}
        </li>
      ))}
    </ul>
  );
};

Breadcrumbs.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, url: PropTypes.string })
  ),
};

export { Breadcrumbs };
