import React, { useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { colors } from "../../../tailwind/colors";

const component = {
  position: "relative",
  display: "inline-block",
  paddingBottom: "4px",
  paddingRight: "4px",
};

const input = {
  display: "inline-block",
  fontSize: "1em",
  padding: "6px 5px 5px",
  marginTop: "4px",
  marginLeft: "6px",
  overflowWrap: "anywhere",
};

const tagStyle = {
  display: "inline-flex",
  backgroundColor: colors.ui[200],
  border: "2px solid transparent",
  marginTop: "4px",
  marginLeft: "4px",
  padding: "4px 6px 3px",
  borderRadius: "4px",
  fontSize: "1em",
  overflowWrap: "anywhere",
};

const tagErrorStyle = {
  ...tagStyle,
  borderColor: colors.pink[500],
};

function InputTag({ value, onChange, className, onBlur, errors }) {
  const ref = useRef();

  const addTag = (e) => {
    const newTags = e.target.innerText.trim().split(",");
    if (newTags.length === 0 || newTags[0] === "") return;
    onChange([...value, ...newTags]);
    e.target.innerText = "";
    onBlur();
  };

  const onKeyDown = (e) => {
    switch (e.key) {
      case ",":
      case "Enter":
        e.preventDefault();
        addTag(e);
        break;
      case "Backspace":
        if (!ref.current.innerText.length) {
          const newTags = [...value];
          newTags.pop();
          onChange(newTags);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div
      style={component}
      className={classnames(
        "bg-white text-black border border-ui-200 rounded",
        className
      )}
      onClick={() => ref.current.focus()}
    >
      {value.map((tag, index) => (
        <div
          key={tag}
          style={
            errors.length > index && errors[index] ? tagErrorStyle : tagStyle
          }
        >
          <div className="mr-2">{tag}</div>
          <span
            onClick={() => {
              const newTags = [...value];
              newTags.splice(index, 1);
              onChange(newTags);
            }}
          >
            <strong className="cursor-pointer">&#xd7;</strong>
          </span>
        </div>
      ))}

      <p
        contentEditable
        ref={ref}
        style={input}
        onKeyDown={onKeyDown}
        onBlur={addTag}
        className="outline-none"
      />
    </div>
  );
}

InputTag.defaultProps = {
  errors: [],
};

InputTag.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  className: PropTypes.string,
  errors: PropTypes.array,
};

export { InputTag };
