import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./index.module.css";

const CopyButton = (props) => {
  const { value } = props;

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(timeout);
    }
  }, [copied]);

  return (
    <button
      className={classnames(
        "bg-pink-500 text-white text-xs font-semibold uppercase p-2 rounded-full focus:outline-none transition-color duration-300",
        copied && "bg-green-500",
        copied && styles.animate
      )}
      style={{ width: 70 }}
      onClick={() =>
        navigator.clipboard.writeText(value).then(() => setCopied(true))
      }
    >
      {copied ? "Copied" : "Copy"}
    </button>
  );
};

CopyButton.propTypes = {
  /** The value to copy to the clipboard. */
  value: PropTypes.string,
  className: PropTypes.string,
};

CopyButton.defaultProps = {};

export { CopyButton };
