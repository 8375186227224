import React from "react";

import { useRoutes } from "version-one-dev-utils/routes";
import { useSelector } from "version-one-dev-utils/state";

import { Link } from "react-router-dom";

import { ProductionStore } from "../../stores/ProductionStore";

import { Field } from "../../components/Field";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import { Modal } from "../../components/Modal";

import { useForm } from "../../deps/useForm";
import { Form } from "../../deps/Form";
import { string } from "yup";

const OperatorNewProduction = (props) => {
  const { params, routes, link, push } = useRoutes();

  const authUser = useSelector((state) => state.AuthStore);

  const form = useForm({
    schema: {
      name: string().required("Please enter a name"),
      description: string().required("Please enter a description"),
    },
    onSubmit: (values) =>
      ProductionStore.actions
        // Get an empty production object and apply form values to it
        .add({
          ...ProductionStore.select.get(),
          ...values,
          members: [authUser.id],
          managers: [authUser.id],
        })
        .then((action) =>
          push(routes.OPERATOR, { productionId: action.payload })
        ),
  });

  const {
    register,
    formState: { errors, isSubmitting },
  } = form;

  return (
    <Modal title="New Production" onClose={() => push(routes.OPERATOR, params)}>
      <Form config={form}>
        <Field className="mb-10" label="Name" error={errors.name}>
          <Input {...register("name")} />
        </Field>

        <Field className="mb-10" label="Description" error={errors.description}>
          <Input
            {...register("description")}
            container="textarea"
            className="min-h-[100px]"
          />
        </Field>

        <div className="flex items-center mt-16">
          <div className="flex-1 mr-8">
            <Link
              className="font-semibold underline hover:no-underline"
              to={link(routes.OPERATOR, params)}
            >
              Cancel
            </Link>
          </div>
          <Button
            label="Save Production"
            color="primary"
            pending={isSubmitting}
          />
        </div>
      </Form>
    </Modal>
  );
};

export { OperatorNewProduction };
