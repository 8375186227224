import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { TestId } from "version-one-dev-utils/tests";

import { InputTwo } from "../InputTwo";

import { ReactComponent as Show } from "./eye.svg";
import { ReactComponent as Hide } from "./eye-slash.svg";

import styles from "./index.module.css";

export const PasswordInputTwo = forwardRef((props, ref) => {
  const testId = TestId(props);

  const { className, ...other } = props;

  const [show, setShow] = useState(false);

  const Icon = show ? Hide : Show;

  return (
    <div
      {...testId()}
      className={classnames("relative inline-block", className)}
    >
      <InputTwo
        {...other}
        type={show ? "text" : "password"}
        className={classnames(
          "pr-[52px] w-full",
          styles.placeholderStyle,
          styles.inputStyle
        )}
        placeholder="Password"
        ref={ref}
      />
      <div className="absolute top-0 bottom-0 right-4 flex items-center space-x-4">
        <Icon
          className="text-xl text-white cursor-pointer w-[1.5rem] h-[1.5rem]"
          onClick={() => setShow(!show)}
        />
      </div>
    </div>
  );
});

PasswordInputTwo.propTypes = {
  "data-testid": PropTypes.string,
  className: PropTypes.string,
};
