import React, { useEffect, useMemo, useState } from "react";

import { useRoutes } from "version-one-dev-utils/routes";
import { useSelector } from "version-one-dev-utils/state";

import { Link } from "react-router-dom";

import { EditSuiteTagStore } from "../../stores/EditSuiteTagStore";
import { EditSuiteStore } from "../../stores/EditSuiteStore";

import { Field } from "../../components/Field";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import { Modal } from "../../components/Modal";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { TagSelect } from "../TagSelect";
import { UserSelectForForm } from "../UserSelectForForm";

import { useForm } from "../../deps/useForm";
import { useWatch } from "react-hook-form";
import { Form } from "../../deps/Form";
import { string, object, array, lazy } from "yup";

const DEFAULT_EDIT_SUITE = {
  name: "",
  connection: null,
  tags: [],
  user: null,
};

const getConnectionRequired = (value) =>
  Object.values(value || {}).find((v) => v);

const EditSuite = (props) => {
  const { params, routes, link, push } = useRoutes();

  const [isDeleting, setIsDeleting] = useState(false);

  const editSuite = useSelector(
    (state) =>
      state.EditSuiteStore.find(
        (editSuite) => editSuite.id === params.editSuite
      ) || DEFAULT_EDIT_SUITE
  );

  const production = useSelector((state) =>
    state.ProductionStore.find((production) => production.id === params.id)
  );

  const users = useSelector(
    (state) =>
      state.UserStore.filter((user) => production?.members.includes(user.id)) ||
      []
  );

  const isEditing = !!editSuite.id;

  const form = useForm({
    schema: {
      name: string().required("Please enter a name"),
      connection: lazy((value) => {
        // Validation is required if any connection values are set
        return getConnectionRequired(value)
          ? object().shape({
              broker: string().nullable().required("Please add a broker"),
              domain: string().nullable().required("Please add a domain"),
              username: string().nullable().required("Please add a username"),
              password: string().nullable().required("Please add a password"),
              virtualMachine: string()
                .nullable()
                .required("Please add a virtual machine"),
            })
          : object().notRequired().default(null);
      }),
      tags: array(),
      /**
       * Set a custom test for user isRequired
       * User is required if any connection value is set
       * If user is NOT required we will delete it before saving in onSubmit handler
       **/
      user: string().nullable(),
    },
    onSubmit: (values) => {
      return Promise.all(
        // Add new tags
        values.tags
          .filter((tag) => tag.isNew)
          .map(EditSuiteTagStore.actions.add)
      )
        .then((actions) => {
          const newTags = actions.map((action) => action.payload);

          const newWorkspace = {
            production: params.id,
            ...values,
            // Remove connection if not required
            connection: isConnectionRequired ? values.connection : null,
            // Remove user if not requireed
            user: isConnectionRequired ? values.user : null,
            // Update tags and convert back to ids
            tags: values.tags.map((tag) => {
              return tag.isNew
                ? newTags.find((newTag) => newTag.name === tag.name)?.id
                : tag.id;
            }),
          };

          // Save the editSuite
          return isEditing
            ? EditSuiteStore.actions.edit(newWorkspace, editSuite.id)
            : EditSuiteStore.actions.add(newWorkspace);
        })
        .then(() => push(routes.ADMIN_PRODUCTION, params));
    },
  });

  const {
    register,
    //watch,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = form;

  // Watch the value of connection so we can toggle the user input on/off
  const connection = useWatch({ control, name: "connection" });

  const isConnectionRequired = useMemo(
    () => getConnectionRequired(connection),
    [connection]
  );

  /**
   * Async default values
   */
  useEffect(() => {
    reset({
      ...editSuite,
      // Convert tags to objects
      tags: editSuite.tags.map((tag) => ({ id: tag })),
    });
  }, [editSuite, reset]);

  return isDeleting ? (
    <ConfirmationModal
      title={`Delete ${editSuite.name} from edit suites?`}
      confirm="Yes"
      dismiss="No"
      onConfirm={() =>
        EditSuiteStore.actions
          .remove(editSuite, editSuite.id)
          .then(() => push(routes.ADMIN_PRODUCTION, params))
      }
      onClose={() => setIsDeleting(false)}
    />
  ) : (
    <Modal
      title={`${isEditing ? "" : "New"} Edit Suite`}
      onClose={() => push(routes.ADMIN_PRODUCTION, params)}
    >
      <Form config={form}>
        <Field className="mb-10" label="Name" error={errors.name}>
          <Input {...register("name")} />
        </Field>

        <Field className="mb-10" label="Tags" error={errors.tags}>
          <TagSelect {...register("tags")} type="editSuite" />
        </Field>

        <Field className="mb-10" label="Connection">
          <div className="grid grid-cols-2 gap-8">
            <Field
              label="Broker"
              type="sub"
              error={isConnectionRequired && errors.connection?.broker}
              className="mb-4"
            >
              <Input {...register("connection.broker")} />
            </Field>

            <Field
              label="Domain"
              type="sub"
              error={isConnectionRequired && errors.connection?.domain}
              className="mb-4"
            >
              <Input {...register("connection.domain")} />
            </Field>

            <Field
              label="Username"
              type="sub"
              error={isConnectionRequired && errors.connection?.username}
              className="mb-4"
            >
              <Input {...register("connection.username")} />
            </Field>

            <Field
              label="Password"
              type="sub"
              error={isConnectionRequired && errors.connection?.password}
              className="mb-4"
            >
              <Input {...register("connection.password")} />
            </Field>

            <Field
              label="Virtual Machine"
              type="sub"
              error={isConnectionRequired && errors.connection?.virtualMachine}
              className="mb-4"
            >
              <Input {...register("connection.virtualMachine")} />
            </Field>
          </div>
        </Field>

        <Field
          className="mb-10"
          label="User"
          error={errors.user}
          disabled={!isConnectionRequired}
        >
          <UserSelectForForm
            {...register("user")}
            users={users}
            filters={{ role: "operator", deleted: false }}
            isDisabled={!isConnectionRequired}
          />
        </Field>

        {isEditing && (
          <Button
            className="mt-5"
            type="button"
            label="Delete Edit Suite"
            onClick={() => setIsDeleting(true)}
          />
        )}

        <div className="flex items-center mt-16">
          <div className="flex-1 mr-8">
            <Link
              className="font-semibold underline hover:no-underline"
              to={link(routes.ADMIN_PRODUCTION, params)}
            >
              Cancel
            </Link>
          </div>
          <Button
            label="Save Edit Suite"
            color="primary"
            pending={isSubmitting}
          />
        </div>
      </Form>
    </Modal>
  );
};

export { EditSuite };
