import React from "react";
import PropTypes from "prop-types";

import { useSelector } from "version-one-dev-utils/state";

import { EditSuiteStore } from "../../stores/EditSuiteStore";

import { InfoCell } from "../../components/InfoCell";

const EditSuiteCell = (props) => {
  const { id } = props;

  const editSuite = useSelector(() => EditSuiteStore.select.get(id));
  return <InfoCell name={editSuite.name} description={editSuite.id} />;
};

EditSuiteCell.propTypes = {
  id: PropTypes.string.isRequired,
};

export { EditSuiteCell };
