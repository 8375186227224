import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";

import { DatePicker } from "../DatePicker";

const DatePickerForForm = forwardRef((props, ref) => {
  const {
    field: { value, onChange, onBlur },
  } = useController({ name: props.name, defaultValue: props.value });

  return (
    <DatePicker
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      className={props.className}
    />
  );
});

DatePickerForForm.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};

export { DatePickerForForm };
