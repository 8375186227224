import { createStore } from "version-one-dev-utils/state";
import PropTypes from "prop-types";
import sha1 from "crypto-js/sha1";

import { Firebase } from "../Firebase";

let unsubscribe;

const watch = () => (resolve, reject) => {
  unsubscribe && unsubscribe();

  unsubscribe = Firebase.firestore()
    .collection("editsuitetags")
    .onSnapshot((querySnapshot) => {
      const editSuiteTags = [];

      querySnapshot.forEach((doc) => {
        editSuiteTags.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      resolve(editSuiteTags);
    }, reject);
};

watch.success = (state, action) => action.payload;

const add = (props) => {
  const id = sha1(props.name).toString();

  return Firebase.firestore()
    .collection("editsuitetags")
    .doc(id)
    .set({ name: props.name })
    .then(() => Firebase.firestore().collection("editsuitetags").doc(id).get())
    .then((doc) => ({ id: doc.id, name: props.name }))
    .catch((error) => {
      console.error("Error", String(error));
      return Promise.reject(new Error(String(error)));
    });
};

add.propTypes = {
  name: PropTypes.string.isRequired,
};

export const EditSuiteTagStore = createStore({
  name: "EditSuiteTagStore",
  initialState: [],
  actions: { watch, add },
  propTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
});

EditSuiteTagStore.select = {
  get: (id) =>
    EditSuiteTagStore.getState().find((v) => v.id === id) || {
      id,
      name: "Missing Tag",
    },
};
