import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const Input = forwardRef((props, ref) => {
  const { name, size, readOnly, className, ...inputProps } = props;

  return (
    <div className="relative">
      {React.createElement(props.container, {
        className: classnames(
          "bg-white text-black border border-ui-200 inline-block rounded focus:border-ui-400 outline-none",
          size === "small" && "py-1 px-2",
          size === "medium" && "p-2",
          size === "large" && "p-4",
          readOnly && "bg-grey50 focus:border-ui-200",
          className
        ),
        name,
        readOnly,
        ...inputProps,
        ref,
      })}
    </div>
  );
});

Input.propTypes = {
  container: PropTypes.oneOf(["input", "textarea"]),
  name: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  readOnly: PropTypes.bool,
  className: PropTypes.string,
};

Input.defaultProps = {
  container: "input",
  size: "medium",
  readOnly: false,
};

export { Input };
