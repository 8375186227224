import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { TestId } from "version-one-dev-utils/tests";

import { TermsOfUse } from "../../components/TermsOfUse";

import { ReactComponent as Logo } from "./logo.svg";
import backgroundImage from "./bg.png";

export const Footer = (props) => {
  const testId = TestId(props);

  const { className } = props;

  const [modal, setModal] = useState(null);

  return (
    <div
      {...testId()}
      className={classnames("relative p-8 bg-grey-900 flex", className)}
    >
      <div
        className="absolute left-0 bg-cover w-full"
        style={{
          top: -400,
          height: 400,
          backgroundImage: `url(${backgroundImage})`,
        }}
      />
      <div className="space-y-4 flex-1">
        <Logo />
        <p className="text-2xs" style={{ color: "#C2C6DB" }}>
          © Racoon Media Services Ltd. {new Date().getFullYear()}
        </p>
      </div>
      <nav className="space-y-4">
        {/* <span
          className="block cursor-pointer relative z-50"
          style={{ color: "#C2C6DB" }}
          onClick={() => setModal("terms")}
        >
          Terms
        </span>
        <span
          className="block cursor-pointer"
          style={{ color: "#C2C6DB" }}
          onClick={() => setModal("privacy")}
        >
          Privacy policy
        </span> */}
      </nav>

      {modal === "terms" && <TermsOfUse onClose={() => setModal(null)} />}
      {modal === "privacy" && <TermsOfUse onClose={() => setModal(null)} />}
    </div>
  );
};

Footer.propTypes = {
  "data-testid": PropTypes.string,
  className: PropTypes.string,
};
