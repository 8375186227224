import React from "react";
import PropTypes from "prop-types";

import { Switch as ReactRouterSwitch, Redirect, Route } from "react-router-dom";

import { useRoutes } from "version-one-dev-utils/routes";

const Switch = (props) => {
  const { children } = props;
  const { link, routes } = useRoutes();

  return (
    <ReactRouterSwitch>
      {children}
      <Route path="*">
        <Redirect to={link(routes.NOT_FOUND)} />
      </Route>
    </ReactRouterSwitch>
  );
};

Switch.propTypes = {
  children: PropTypes.any,
};

export { Switch };
