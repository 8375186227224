import React from "react";
import { Link } from "react-router-dom";

import { AuthStore } from "../../stores/AuthStore";
import { useRoutes } from "version-one-dev-utils/routes";
import { useErrors } from "version-one-dev-utils/state";

import { Form } from "../../deps/Form";
import { useForm } from "../../deps/useForm";
import { string, number } from "yup";

import { Field } from "../../components/Field";
import { DatePickerForForm } from "../../components/DatePickerForForm";
import { Input } from "../../components/Input";
import { PasswordInput } from "../../components/PasswordInput";
import { Button } from "../../components/Button";

import { ReactComponent as Logo } from "../../images/logo.svg";
import BackgroundImage from "../../images/bg-new.jpg";

const SignUp = () => {
  const { routes, link, push } = useRoutes();
  const { getError } = useErrors();
  const error = getError(AuthStore.actions.signup);

  const form = useForm({
    schema: {
      firstName: string().required("Please enter your first name"),
      lastNames: string().required("Please enter your last name(s)"),
      dateOfBirth: number()
        .typeError("Please enter your date of birth")
        .required("Please enter your date of birth"),
      email: string()
        .required("Please enter your email address")
        .email("Invalid email address"),
      password: string()
        .min(2, "Password must contain at least 2 characters")
        .required("Please enter your password"),
      company: string(),
    },
    onSubmit: AuthStore.actions.signup,
  });

  const {
    register,
    formState: { errors, isSubmitting },
  } = form;

  return (
    <div className="bg-black h-screen flex flex-col items-center justify-center min-h-[750px]">
      <div
        className="bg-cover bg-center	opacity-60 h-screen w-full absolute"
        style={{
          backgroundImage: `url(${BackgroundImage})`,
        }}
      />
      <header className="relative w-full px-8 pt-8 flex items-center">
        <Logo />
        <div className="flex-1 text-right">
          <Button
            label="Login"
            color="primary"
            onClick={() => push(routes.LOGIN)}
          />
        </div>
      </header>
      <div className="p-8 relative flex-1 flex flex-col items-center justify-center">
        <div className="flex-1 flex flex-col justify-center">
          <div className="text-white text-center font-bold mb-10">
            Sign Up and Join RACOON
          </div>
          <Form config={form} className="text-sm">
            {error && (
              <div className="text-pink-500 text-center font-semibold mb-8">
                {error.value.message}
              </div>
            )}
            <div className="grid grid-cols-2 gap-2 mb-7 ">
              <Field error={errors.firstName}>
                <Input
                  {...register("firstName")}
                  className="w-full"
                  size="large"
                  placeholder="First name"
                />
              </Field>
              <Field error={errors.lastNames}>
                <Input
                  {...register("lastNames")}
                  className="w-full"
                  size="large"
                  placeholder="Last name(s)"
                />
              </Field>
            </div>
            <Field
              error={errors.dateOfBirth}
              label="Date of Birth"
              className="text-white mb-7"
            >
              <DatePickerForForm name="dateOfBirth" />
            </Field>
            <Field error={errors.email} className="mb-7">
              <Input
                {...register("email")}
                className="w-full"
                size="large"
                placeholder="Email Address"
                autoComplete="email"
              />
            </Field>
            <Field error={errors.password} className="mb-7">
              <PasswordInput
                {...register("password")}
                className="w-full"
                size="large"
                placeholder="Password"
                autoComplete="new-password"
              />
            </Field>
            <Field className="mb-7">
              <Input
                {...register("company")}
                className="w-full"
                size="large"
                placeholder="Company (optional)"
              />
            </Field>

            <Button
              label="Create Account"
              color="primary"
              size="large"
              className="w-full"
              pending={isSubmitting}
            />
          </Form>
        </div>
        <div className="flex flex-row justify-center text-xs mt-8">
          <div className="text-white font-bold">Already have an account?</div>
          <Link
            to={link(routes.LOGIN)}
            className="ml-1 text-pink-500 font-bold"
          >
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export { SignUp };
