import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Button } from "../Button";
import { SearchField } from "../SearchField";

const Table = (props) => {
  const {
    columns,
    items,
    addButton,
    filter,
    filterPlaceholder,
    placeholder,
    isRowPending,
    onClickRow,
  } = props;

  const showHeader = addButton || filter;

  const [state, setState] = useState(items);
  const [filterValue, setFilterValue] = useState("");

  useEffect(() => {
    if (filterValue) {
      setState(filter(items, filterValue));
    } else {
      setState(items);
    }
  }, [items, filter, filterValue]);

  return (
    <div className="border border-ui-200 divide-y divide-ui-200 bg-white rounded-lg overflow-hidden">
      {showHeader && (
        <div className="flex p-4">
          <div className="flex-1">
            {addButton && (
              <Button size="small" color="primary" {...addButton} />
            )}
          </div>
          {filter && (
            <div>
              <SearchField
                size="small"
                value={filterValue}
                placeholder={filterPlaceholder}
                onChange={(e) => setFilterValue(e.target.value)}
              />
            </div>
          )}
        </div>
      )}
      <table className="w-full">
        <thead>
          <tr className="bg-ui-50 border-b border-ui-200">
            {columns.map((column, index) => (
              <td
                key={index}
                className="p-4 text-sm text-ui-500 font-semibold"
                style={{ minWidth: column.minWidth }}
              >
                {column.name}
              </td>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-ui-200">
          {state.map((item, index) => (
            <tr
              key={index}
              className={classnames(
                isRowPending && isRowPending(item) && "bg-ui-50",
                "hover:bg-ui-50",
                onClickRow && "cursor-pointer"
              )}
              onClick={onClickRow ? () => onClickRow(item) : null}
            >
              {columns.map((column, index) => (
                <td key={index} className="p-4">
                  {column.render ? column.render(item) : item[column.key]}
                </td>
              ))}
            </tr>
          ))}
          {!state.length && (
            <tr>
              <td
                className="p-4 text-center text-ui-300"
                colSpan={columns.length}
              >
                {filterValue ? "No matches" : placeholder}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string.isRequired,
      render: PropTypes.func,
      minWidth: PropTypes.number,
    })
  ),
  items: PropTypes.arrayOf(PropTypes.object),
  addButton: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  filter: PropTypes.func,
  filterPlaceholder: PropTypes.string,
  placeholder: PropTypes.string,
  isRowPending: PropTypes.func,
  onClickRow: PropTypes.func,
};

Table.defaultProps = {
  placeholder: "No data",
};

export { Table };
