import React, { useCallback, useEffect } from "react";

import { useRoutes } from "version-one-dev-utils/routes";
import { useSelector } from "version-one-dev-utils/state";

import { Link } from "react-router-dom";

import { ProductionStore } from "../../stores/ProductionStore";
import { EditSuiteStore } from "../../stores/EditSuiteStore";

import { Field } from "../../components/Field";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import { Modal } from "../../components/Modal";
import { SelectForForm } from "../../components/SelectForForm";
import { Toggle } from "../../components/Toggle";

import { UserSelectForForm } from "../../containers/UserSelectForForm";

import { useForm } from "../../deps/useForm";
import { useWatch } from "react-hook-form";
import { Form } from "../../deps/Form";
import { array, string, object } from "yup";

const softwareOptions = [
  { value: "Avid MC", label: "Avid MC", plugins: ["Boris", "Sapphire"] },
  { value: "Adobe Premiere", label: "Adobe Premiere" },
];

const specOptions = [
  { value: "Offline Spec", label: "Offline Spec" },
  { value: "Producer Spec", label: "Producer Spec" },
  { value: "Online Spec", label: "Online Spec" },
];

const RequestEditSuite = (props) => {
  const { params, routes, link, push } = useRoutes();

  const production = useSelector(() =>
    ProductionStore.select.get(params.productionId)
  );

  const users = useSelector((state) =>
    state.UserStore.filter((user) => production.members.includes(user.id))
  );

  const form = useForm({
    schema: {
      name: string().required("Please enter a name"),
      description: string().required("Please enter a description"),
      software: object().shape({ label: string(), value: string() }).required(),
      plugins: array().of(string()).required(),
      spec: object().shape({ label: string(), value: string() }).required(),
      user: string().required(),
    },
    defaultValues: {
      software: softwareOptions[0],
      spec: specOptions[0],
      plugins: [],
    },
    onSubmit: (values) =>
      EditSuiteStore.actions
        .request({
          production: production.id,
          name: values.name,
          description: values.description,
          software: values.software.value,
          softwarePlugins: values.plugins,
          softwareSpec: values.spec.value,
          user: values.user,
        })
        .then(close),
  });

  const {
    register,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = form;

  // Watch the value of software so we can show/hide plugins
  const software = useWatch({ control, name: "software" });
  const plugins = useWatch({ control, name: "plugins" });

  const setPlugin = useCallback(
    (plugin, value) => {
      const newPlugins = [...plugins];

      if (value) {
        newPlugins.push(plugin);
      } else {
        newPlugins.splice(newPlugins.indexOf(plugin), 1);
      }

      setValue("plugins", newPlugins);
    },
    [plugins, setValue]
  );

  useEffect(() => {
    setValue("plugins", []);
  }, [software, setValue]);

  const close = () => push(routes.OPERATOR, params);

  return (
    <Modal title="Request an Edit Suite" onClose={close}>
      <Form config={form}>
        <Field className="mb-10" label="Name" error={errors.name}>
          <Input
            {...register("name")}
            placeholder="What would you like your suite to be called?"
          />
        </Field>
        <Field className="mb-10" label="Description" error={errors.description}>
          <Input
            {...register("description")}
            container="textarea"
            className="min-h-[100px]"
            placeholder="Please provide details of film/series/episodes"
          />
        </Field>
        <Field className="mb-10" label="Software" error={errors.software}>
          <SelectForForm name="software" options={softwareOptions} />
        </Field>
        {software.plugins && (
          <Field className="mb-10" label="Plugins">
            <ul>
              {software.plugins?.map((plugin, index) => (
                <li key={index} className="inline-flex items-center mr-4">
                  <span className="mr-2">{plugin}</span>
                  <Toggle
                    value={plugins.includes(plugin)}
                    onChange={(value) => setPlugin(plugin, value)}
                  />
                </li>
              ))}
            </ul>
          </Field>
        )}

        <Field className="mb-10" label="Software Spec" error={errors.spec}>
          <SelectForForm name="spec" options={specOptions} />
        </Field>

        <Field className="mb-10" label="User" error={errors.user}>
          <UserSelectForForm
            {...register("user")}
            users={users}
            filters={{ role: "operator", deleted: false }}
          />
        </Field>

        <div className="flex items-center mt-16">
          <div className="flex-1 mr-8">
            <Link
              className="font-semibold underline hover:no-underline"
              to={link(routes.OPERATOR, params)}
            >
              Cancel
            </Link>
          </div>
          <Button label="Send Request" color="primary" pending={isSubmitting} />
        </div>
      </Form>
    </Modal>
  );
};

export { RequestEditSuite };
