import React from "react";
import { FormProvider } from "react-hook-form";

import PropTypes from "prop-types";

/**
 * Forms use [react-hook-form](https://react-hook-form.com) to manage state and validation.
 *
 * The Form component renders a form element with noValidate=true (so the useForm hook can handle validation) and
 * creates a [form context](https://react-hook-form.com/api#useFormContext) for children such as the [Field](/docs/forms-field--default) component to access.
 *
 */
export const Form = (props) => {
  const {
    config: { onSubmit, ...values },
    ...form
  } = props;

  return (
    <FormProvider {...values}>
      <form onSubmit={onSubmit} noValidate {...form} />
    </FormProvider>
  );
};

Form.propTypes = {
  config: PropTypes.object,
  children: PropTypes.any,
};
