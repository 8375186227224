import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";

import { TagInput } from "../TagInput";

const TagInputForForm = forwardRef((props, ref) => {
  const {
    field: { value, onChange, onBlur },
  } = useController({ defaultValue: props.value || [], name: props.name });

  return (
    <TagInput
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      className={props.className}
      errors={props.errors}
    />
  );
});

TagInputForForm.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  errors: PropTypes.array,
};

export { TagInputForForm };
