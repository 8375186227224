import LogRocket from "logrocket";

if (process.env.REACT_APP_LOGROCKET_APP_ID) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID);
  console.log("LogRocket - Init");
}

export const LogRocketIdentify = (id, name, email, role) => {
  if (process.env.REACT_APP_LOGROCKET_APP_ID) {
    LogRocket.identify(id, {
      name: name,
      email: email,
      role: role,
    });
    console.log("LogRocket - Identify");
  }
};
