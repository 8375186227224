import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Modal } from "../Modal";
import { Button } from "../Button";

const ConfirmationModal = (props) => {
  const { title, confirm, dismiss, onConfirm, onClose } = props;

  const [pending, setPending] = useState(false);

  useEffect(() => {
    return () => setPending(false);
  }, []);

  return (
    <Modal className="text-center" size="small" title={title}>
      <Button
        className="mr-4 w-[150px]"
        label={confirm}
        color="danger"
        pending={pending}
        onClick={() => {
          setPending(true);
          Promise.resolve(onConfirm()).then(() => pending && setPending(false));
        }}
      />
      <Button className="w-[150px]" label={dismiss} onClick={onClose} />
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  confirm: PropTypes.string,
  dismiss: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

ConfirmationModal.defaultProps = {
  confirm: "Yes",
  dismiss: "Cancel",
};

export { ConfirmationModal };
