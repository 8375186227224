import React, { useEffect, useState } from "react";

import { useRoutes } from "version-one-dev-utils/routes";
import { useSelector } from "version-one-dev-utils/state";

import { ProductionStore } from "../../stores/ProductionStore";
import { InviteStore } from "../../stores/InviteStore";

import { Button } from "../../components/Button";
import { Table } from "../../components/Table";
import { Select } from "../../components/Select";
import { ConfirmationModal } from "../../components/ConfirmationModal";

const roles = [
  { value: "MEMBER", label: "Member" },
  { value: "MANAGER", label: "Production Manager" },
];

const AdminProductionUserTable = () => {
  const { routes, push, params } = useRoutes();

  const production = useSelector((state) =>
    ProductionStore.select.get(params.id)
  );

  const users = useSelector((state) =>
    state.UserStore.filter(
      (user) => production.members.includes(user.id) && !user.deleted
    )
      .map((user) => {
        const role = production.managers.includes(user.id)
          ? "MANAGER"
          : "MEMBER";

        const invite = state.InviteStore.production.find(
          (i) => i.to === user.id
        );
        return { ...user, role, inviteId: invite?.id };
      })
      .sort((a, b) => (a.fullName < b.fullName ? -1 : 1))
  );

  const [isDeletingUser, setIsDeletingUser] = useState(null);

  useEffect(() => {
    InviteStore.actions.watchProduction({ id: params.id });
    return () => InviteStore.actions.unwatchProduction();
  }, [params.id]);

  return (
    <>
      <Table
        columns={[
          {
            name: "Name",
            key: "fullName",
            minWidth: 200,
          },
          {
            name: "Email",
            key: "email",
            minWidth: 250,
          },
          {
            name: "Role",
            key: "role",
            minWidth: 180,
            render: (item) => (
              <Select
                size="small"
                value={roles.find((role) => role.value === item.role)}
                options={roles}
                isDisabled={
                  item.role === "MANAGER" && production.managers.length <= 1
                }
                onChange={(change) =>
                  ProductionStore.actions.setUserRole({
                    id: production.id,
                    userId: item.id,
                    role: change.value,
                  })
                }
              />
            ),
          },
          {
            name: "",
            key: "",
            render: (item) => (
              <div className="flex justify-end">
                <Button
                  label="Remove"
                  size="xsmall"
                  disabled={users.length === 1}
                  onClick={() => setIsDeletingUser(item)}
                />
              </div>
            ),
          },
        ]}
        items={users}
        addButton={{
          label: `Add Users`,
          onClick: () => {
            push(routes.ADMIN_PRODUCTION_ADD_USERS, params);
          },
        }}
        filter={(items, value) => {
          return items.filter(
            (item) =>
              item.fullName.toLowerCase().includes(value.toLowerCase()) ||
              item.email.toLowerCase().includes(value.toLowerCase())
          );
        }}
        filterPlaceholder="Name or email"
        placeholder={`No users`}
      />

      {isDeletingUser && (
        <ConfirmationModal
          title={`Remove ${isDeletingUser.fullName} from ${production.name}`}
          confirm="Yes"
          dismiss="No"
          onConfirm={() => {
            return ProductionStore.actions
              .removeUser(
                { id: production.id, userId: isDeletingUser.id },
                isDeletingUser.id
              )
              .then(() => {
                return isDeletingUser.inviteId
                  ? InviteStore.actions.remove(
                      { id: isDeletingUser.inviteId },
                      isDeletingUser.id
                    )
                  : Promise.resolve();
              })
              .then(() => setIsDeletingUser(null));
          }}
          onClose={() => setIsDeletingUser(null)}
        />
      )}
    </>
  );
};

export { AdminProductionUserTable };
