import React, { useEffect } from "react";
import { Route } from "react-router-dom";

import { useRoutes } from "version-one-dev-utils/routes";
import { useSelector } from "version-one-dev-utils/state";

import { AuthRoute } from "./containers/AuthRoute";
import { Switch } from "./containers/Switch";
import { Login } from "./containers/Login";
import { Register } from "./containers/Register";
import { SignUp } from "./containers/SignUp";
import { SetPassword } from "./containers/SetPassword";
import { ResetPassword } from "./containers/ResetPassword";
import { AdminDashboard } from "./containers/AdminDashboard";
import { OperatorDashboard } from "./containers/OperatorDashboard";
import { FourOhFour } from "./containers/FourOhFour";

import { AuthStore } from "./stores/AuthStore";

function App() {
  const { routes } = useRoutes();
  const ready = useSelector((state) => state.AuthStore.ready);

  useEffect(() => {
    AuthStore.actions.watch();
  }, []);

  return (
    ready && (
      <>
        <Switch>
          <AuthRoute path={routes.HOME} exact component={Login} />

          <AuthRoute path={routes.LOGIN} exact component={Login} />

          <AuthRoute path={routes.REGISTER} exact component={Register} />

          <AuthRoute path={routes.SIGNUP} exact component={SignUp} />

          <AuthRoute
            path={routes.RESET_PASSWORD}
            exact
            component={ResetPassword}
          />

          <AuthRoute path={routes.SET_PASSWORD} exact component={SetPassword} />

          <AuthRoute
            path={routes.SET_PASSWORD_FROM_INVITE}
            exact
            component={SetPassword}
          />

          <AuthRoute
            role="admin"
            path={routes.ADMIN}
            component={AdminDashboard}
          />

          <AuthRoute
            role="operator"
            path={routes.OPERATOR}
            component={OperatorDashboard}
          />

          <Route path={routes.NOT_FOUND} component={FourOhFour} />
        </Switch>
      </>
    )
  );
}

export { App };
