import React from "react";
import PropTypes from "prop-types";

function DateString(props) {
  let value = "";

  if (props.value) {
    value = props.format;

    const date = new Date(props.value);
    const keys = {
      YYYY: date.getFullYear(),
      MM: (date.getMonth() + 1).toString().padStart(2, "0"),
      DD: date.getDate().toString().padStart(2, "0"),
      HH: date.getHours().toString().padStart(2, "0"),
      mm: date.getMinutes().toString().padStart(2, "0"),
    };

    Object.keys(keys).forEach((key) => {
      value = value.replace(key, keys[key]);
    });
  }

  return <span className={props.className}>{value}</span>;
}

DateString.defaultProps = {
  format: "YYYY-MM-DD HH:mm",
};

DateString.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  format: PropTypes.string,
  className: PropTypes.string,
};

export { DateString };
