import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Input } from "../Input";
import { Icon } from "../Icon";

const SearchField = (props) => {
  const { size, ...inputProps } = props;

  return (
    <div className="relative inline-block">
      <Input
        {...inputProps}
        className={classnames(
          size === "small" && "pr-9",
          size === "medium" && "pr-11"
        )}
        size={size}
      />
      <div
        className={classnames(
          "absolute top-0 bottom-0 right-0 flex justify-center items-center text-ui-400",
          size === "small" && "text-lg w-9",
          size === "medium" && "text-xl w-11"
        )}
      >
        <button className="focus:outline-none leading-none">
          <Icon icon={["fas", "search"]} />
        </button>
      </div>
    </div>
  );
};

SearchField.propTypes = {
  size: PropTypes.oneOf(["small", "medium"]),
};

SearchField.defaultProps = {
  size: "medium",
};

export { SearchField };
