import { useForm as useFormHook } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object } from "yup";

export const useForm = (config = {}) => {
  const { schema, onSubmit, ...other } = config;

  const value = useFormHook({
    mode: "onTouched",
    resolver: schema ? yupResolver(object().shape(schema)) : null,
    ...other,
  });

  return {
    ...value,
    onSubmit: value.handleSubmit(onSubmit),
  };
};
