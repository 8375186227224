import React from "react";

import { useRoutes } from "version-one-dev-utils/routes";
import { useSelector } from "version-one-dev-utils/state";

import { Link } from "react-router-dom";

import { ProductionStore } from "../../stores/ProductionStore";

import { Field } from "../../components/Field";
import { Button } from "../../components/Button";
import { Modal } from "../../components/Modal";
import { UserSelectForForm } from "../UserSelectForForm";

import { useForm } from "../../deps/useForm";
import { Form } from "../../deps/Form";
import { array } from "yup";

const AddUsers = (props) => {
  const { params, routes, link, push } = useRoutes();

  const production = useSelector((state) =>
    state.ProductionStore.find((production) => production.id === params.id)
  );

  const form = useForm({
    schema: {
      members: array(),
    },
    onSubmit: (values) => {
      const newProduction = {
        ...production,
        // Update users and convert back to ids
        members: [...production.members, ...values.members],
      };

      // Save the production
      return ProductionStore.actions
        .edit(newProduction, production.id)
        .then(() => push(routes.ADMIN_PRODUCTION, params));
    },
  });

  const {
    register,
    formState: { errors, isSubmitting },
  } = form;

  return (
    <Modal
      title="Add Users"
      onClose={() => push(routes.ADMIN_PRODUCTION, params)}
    >
      <Form config={form}>
        <Field className="mb-10" label="User" error={errors.members}>
          <UserSelectForForm
            {...register("members")}
            filters={{ role: "operator", deleted: false }}
            isMulti
          />
        </Field>

        <div className="flex items-center mt-16">
          <div className="flex-1 mr-8">
            <Link
              className="font-semibold underline hover:no-underline"
              to={link(routes.ADMIN_PRODUCTION, params)}
            >
              Cancel
            </Link>
          </div>
          <Button label="Save Users" color="primary" pending={isSubmitting} />
        </div>
      </Form>
    </Modal>
  );
};

export { AddUsers };
