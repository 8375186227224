import React, { useEffect } from "react";
import { useState } from "react";
import PropTypes from "prop-types";

import { Select } from "../Select";

const localeYear = new Date().getFullYear();

// Works out how days in the month based on the Month and Year
const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};

// Adding ordinal to day
const ordinal = (number) => {
  const ordinalRules = new Intl.PluralRules("en", {
    type: "ordinal",
  });

  const suffixes = {
    one: "st",
    two: "nd",
    few: "rd",
    other: "th",
  };
  const suffix = suffixes[ordinalRules.select(number)];
  return number + suffix;
};

Array.from([...Array(100).keys()], (x) => localeYear - x);
const allDays = Array.from(Array(31), (v, i) => ({
  value: i + 1,
  label: ordinal(i + 1),
}));

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
].map((label, i) => ({ value: i + 1, label }));

const DatePicker = (props) => {
  const { value, onChange } = props;

  const [days, setDays] = useState(allDays);

  const [years] = useState(
    Array.from([...Array(100).keys()], (x) => ({
      value: localeYear - x,
      label: (localeYear - x).toString(),
    }))
  );

  const date = typeof value === "number" && new Date(value);
  const [currentDay, setCurrentDay] = useState(date && date.getDate());
  const [currentMonth, setCurrentMonth] = useState(date && date.getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(date && date.getFullYear());

  useEffect(() => {
    const numDays =
      currentMonth && currentYear
        ? daysInMonth(currentMonth, currentYear)
        : allDays.length;
    const newDays = [...allDays];
    newDays.length = numDays;
    setDays(newDays);

    if (currentDay > numDays) {
      setCurrentDay(numDays);
    } else if (currentYear && currentMonth && currentDay) {
      onChange(new Date(currentYear, currentMonth - 1, currentDay).valueOf());
    }
  }, [currentDay, currentMonth, currentYear, onChange]);

  return (
    <div className="grid grid-cols-3 gap-2 text-black">
      <Select
        value={days[currentDay - 1]}
        options={days}
        placeholder="Day"
        onChange={(e) => setCurrentDay(e.value)}
      />
      <Select
        value={months[currentMonth - 1]}
        options={months}
        placeholder="Month"
        onChange={(e) => setCurrentMonth(e.value)}
      />
      <Select
        value={years.find((year) => year.value === currentYear)}
        placeholder="Year"
        options={years}
        onChange={(e) => setCurrentYear(e.value)}
      />
    </div>
  );
};

DatePicker.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

DatePicker.defaultProps = {
  //value: Date.now(),
};

export { DatePicker };
