import React, { useEffect, useState, useMemo } from "react";
import { Switch, Route, Link, Redirect } from "react-router-dom";

import classnames from "classnames";

import { useRoutes } from "version-one-dev-utils/routes";
import { useSelector, usePending } from "version-one-dev-utils/state";

import { EditSuiteTagStore } from "../../stores/EditSuiteTagStore";
import { ProductionTagStore } from "../../stores/ProductionTagStore";
import { EditSuiteStore } from "../../stores/EditSuiteStore";
import { ProductionStore } from "../../stores/ProductionStore";
import { UserStore } from "../../stores/UserStore";
import { InviteStore } from "../../stores/InviteStore";

import { Button } from "../../components/Button";
import { Invite } from "../../components/Invite";
import { Loader } from "../../components/Loader";
import { ProductionInfo } from "../../components/ProductionInfo";
import { EditSuite } from "../../components/EditSuite";
import { Breadcrumbs } from "../../components/Breadcrumbs";

import { Header } from "../Header";
import { OperatorTerms } from "../OperatorTerms";
import { OperatorNewProduction } from "../OperatorNewProduction";
import { OperatorEditSuite } from "../OperatorEditSuite";
import { UserCell } from "../UserCell";
import { OperatorProductionUserTable } from "../OperatorProductionUserTable";
import { InviteUsers } from "../InviteUsers";
import { RequestEditSuite } from "../RequestEditSuite";

import EditSuitesIcon from "../../images/editSuitesIcon.svg";
import { ReactComponent as NewProductionIcon } from "./newProductionIcon.svg";
import { ReactComponent as UsersIcon } from "./usersIcon.svg";

import styles from "./index.module.css";

const OperatorDashboard = () => {
  const { routes, link, push, params, query } = useRoutes();

  const { getPending } = usePending();

  const {
    productions,
    production,
    editSuites,
    user,
    invites,
    productionInvites,
  } = useSelector((state) => ({
    productions: state.ProductionStore.filter(
      (production) =>
        !production.deleted &&
        !state.InviteStore.user.find(
          (invite) => invite.production === production.id
        )
    ).sort((a, b) => (a.name < b.name ? -1 : 1)),
    production: state.ProductionStore.find(
      (production) =>
        production.id === params.productionId &&
        !production.deleted &&
        !state.InviteStore.user.find(
          (invite) => invite.production === production.id
        )
    ),
    editSuites: state.EditSuiteStore.filter(
      (editSuite) =>
        editSuite.production === params.productionId &&
        editSuite.user &&
        !editSuite.deleted
    )
      .map((editSuite) => ({
        ...editSuite,
        isMine: editSuite.user === state.AuthStore.id,
      }))
      .sort((a, b) => (a.isMine ? -1 : 1)),
    user: state.AuthStore,
    invites: state.InviteStore.user,
    productionInvites: state.InviteStore.production,
  }));

  const role = useMemo(() => {
    return production?.managers.includes(user.id) ? "MANAGER" : "MEMBER";
  }, [production, user]);

  useEffect(() => {
    // Reroute to first production if id is missing
    if (!params.productionId && productions.length) {
      push(routes.OPERATOR, { productionId: productions[0].id });
    }
  }, [params, productions, push, routes]);

  useEffect(() => {
    if (user.id) {
      EditSuiteTagStore.actions.watch();
      ProductionTagStore.actions.watch();
      ProductionStore.actions.watch({ user: user.id });
      UserStore.actions.watch();
      InviteStore.actions.watchUser({ id: user.id });
    }
  }, [user.id]);

  useEffect(() => {
    if (user && production && role) {
      if (role === "MANAGER") {
        EditSuiteStore.actions.watch({
          production: production.id,
        });
      } else {
        EditSuiteStore.actions.watch({
          user: user.id,
          production: production.id,
        });
      }

      return () => EditSuiteStore.actions.unwatch();
    }
  }, [user, production, role]);

  useEffect(() => {
    if (params.productionId && role === "MANAGER") {
      InviteStore.actions.watchProduction({ id: params.productionId });
      return () => InviteStore.actions.unwatchProduction();
    }
  }, [params.productionId, role]);

  const [pending, setPending] = useState(true);

  useEffect(() => {
    const value = !!getPending(
      EditSuiteTagStore.actions.watch,
      ProductionTagStore.actions.watch,
      ProductionStore.actions.watch,
      UserStore.actions.watch,
      EditSuiteStore.actions.watch,
      InviteStore.actions.watchUser,
      InviteStore.actions.watchProduction
    );

    if (value !== pending) {
      const timeout = setTimeout(() => setPending(value), 200);
      return () => clearTimeout(timeout);
    }
  }, [getPending, setPending, pending]);

  return (
    <>
      <Header />
      {pending && (
        <div className="text-pink-500 text-3xl">
          <Loader />
        </div>
      )}

      {!pending && (
        <Switch>
          <Route path={routes.OPERATOR_USERS}>
            {role === "MEMBER" && (
              <Redirect to={link(routes.OPERATOR, params)} />
            )}

            {role === "MANAGER" && production && (
              <div className="p-8">
                <Breadcrumbs
                  className="mb-6"
                  items={[
                    {
                      name: production.name,
                      url: link(routes.OPERATOR, params),
                    },
                    {
                      name: "Users",
                    },
                  ]}
                />

                <OperatorProductionUserTable />
                <Route path={routes.OPERATOR_INVITE} component={InviteUsers} />
              </div>
            )}
          </Route>
          <Route>
            <>
              {
                <div
                  className={classnames(
                    styles.page,
                    "flex divide-x divide-ui-200"
                  )}
                  style={{ minHeight: `calc(100vh - 77px)` }}
                >
                  <div className="flex-none w-[350px] p-8">
                    {productions.length ? (
                      <Button
                        label="New Production"
                        icon={NewProductionIcon}
                        className="mb-8 w-full"
                        onClick={() =>
                          push(routes.OPERATOR, params, { modal: "new" })
                        }
                      />
                    ) : null}
                    {productions.map((production) => (
                      <Link
                        key={production.id}
                        to={link(routes.OPERATOR, {
                          productionId: production.id,
                        })}
                        onClick={() =>
                          params.productionId !== production.id &&
                          setPending(true)
                        }
                        className={classnames(
                          "block mb-8 p-6 rounded-lg border",
                          params.productionId !== production.id &&
                            "border-ui-200 bg-ui-100 bg-opacity-50 hover:bg-ui-200",
                          params.productionId === production.id &&
                            "bg-ui-200 border-ui-300"
                        )}
                      >
                        <ProductionInfo
                          {...production}
                          tags={production.tags.map(
                            ProductionTagStore.select.get
                          )}
                          isActive={params.productionId === production.id}
                        />
                      </Link>
                    ))}
                  </div>

                  <div className="flex-1 p-8 flex flex-col">
                    <div>
                      {invites.map((invite) => (
                        <div key={invite.id} className="mb-2 last:mb-8">
                          <Invite
                            name={UserStore.select.get(invite.from).fullName}
                            production={
                              ProductionStore.select.get(invite.production).name
                            }
                            onAccept={() => {
                              return InviteStore.actions
                                .remove(invite)
                                .then(() =>
                                  push(routes.OPERATOR, {
                                    productionId: invite.production,
                                  })
                                );
                            }}
                            onDecline={() => {
                              ProductionStore.actions
                                .removeUser({
                                  id: invite.production,
                                  userId: invite.to,
                                })
                                .then(() => InviteStore.actions.remove(invite));
                            }}
                          />
                        </div>
                      ))}
                    </div>

                    {productions.length ? (
                      <>
                        <div className="mb-8 flex items-center">
                          <div className="flex-1 flex items-center">
                            <img
                              src={EditSuitesIcon}
                              alt="Edit Suites"
                              className="mb-1"
                            />
                            <span className="text-xl font-bold ml-2">
                              Edit Suites
                            </span>
                          </div>
                          {role === "MANAGER" && (
                            <Button
                              label="Request New Edit Suite"
                              onClick={() =>
                                push(routes.OPERATOR_REQUEST_EDIT_SUITE, params)
                              }
                            />
                          )}
                        </div>

                        {editSuites.map((editSuite, index) => (
                          <div
                            key={index}
                            className={classnames(
                              styles.editSuite,
                              "rounded-3xl mb-6"
                            )}
                          >
                            {editSuite.isMine && (
                              <Link
                                to={link(routes.OPERATOR_EDIT_SUITE, {
                                  ...params,
                                  editSuite: editSuite.id,
                                })}
                              >
                                <EditSuite
                                  {...editSuite}
                                  tags={editSuite.tags.map(
                                    EditSuiteTagStore.select.get
                                  )}
                                />
                              </Link>
                            )}
                            {!editSuite.isMine && (
                              <EditSuite
                                {...editSuite}
                                tags={editSuite.tags.map(
                                  EditSuiteTagStore.select.get
                                )}
                                assignedTo={
                                  UserStore.select.get(editSuite.user).fullName
                                }
                              />
                            )}
                          </div>
                        ))}
                      </>
                    ) : null}

                    {(!productions.length ||
                      !production ||
                      !editSuites.length) && (
                      <div className="flex-1 flex flex-col items-center justify-center">
                        <span className="text-grey-400 text-2xl text-center">
                          {!productions.length
                            ? "You have not been added to any productions."
                            : !production
                            ? "You are not a member of this production."
                            : "You have not been assigned any edit suites."}
                        </span>
                        {!productions.length ? (
                          <div className="mt-8">
                            <Button
                              label="Create a Production"
                              className="mb-8 w-full"
                              onClick={() =>
                                push(routes.OPERATOR, params, { modal: "new" })
                              }
                            />
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>

                  <div className="flex-none w-[255px] p-8">
                    {production && (
                      <>
                        {role === "MANAGER" && (
                          <Button
                            label="Manage Users"
                            icon={UsersIcon}
                            className="mb-8 w-full"
                            onClick={() => push(routes.OPERATOR_USERS, params)}
                          />
                        )}
                        {production?.members
                          // Filter out invited users
                          .filter(
                            (id) =>
                              !productionInvites.find(
                                (invite) => invite.to === id
                              )
                          )
                          .map((id) => (
                            <div className="mb-3" key={id}>
                              <UserCell id={id} />
                            </div>
                          ))}
                      </>
                    )}
                  </div>
                </div>
              }

              <Switch>
                {/*<Route
                  path={routes.OPERATOR_NEW_PRODUCTION}
                  component={OperatorNewProduction}
                />*/}
                <Route
                  path={routes.OPERATOR_REQUEST_EDIT_SUITE}
                  component={RequestEditSuite}
                />
                <Route
                  path={routes.OPERATOR_EDIT_SUITE}
                  component={OperatorEditSuite}
                />
              </Switch>
            </>
          </Route>
        </Switch>
      )}

      {query.modal === "new" && <OperatorNewProduction />}
      {!user.agreedTerms && <OperatorTerms />}
    </>
  );
};

export { OperatorDashboard };
