import React from "react";
import PropTypes from "prop-types";

import { useSelector } from "version-one-dev-utils/state";

import { UserStore } from "../../stores/UserStore";

import { InfoCell } from "../../components/InfoCell";

const UserCell = (props) => {
  const { id } = props;

  const user = useSelector(() => UserStore.select.get(id));

  return (
    <InfoCell
      name={`${user.firstName} ${user.lastNames}`}
      description={user.email}
    />
  );
};

UserCell.propTypes = {
  id: PropTypes.string.isRequired,
};

export { UserCell };
