import React from "react";

import { AccessPage } from "../../components/AccessPage";
import { Button } from "../../components/Button";

import { AuthStore } from "../../stores/AuthStore";

const AccountDeleted = () => {
  return (
    <AccessPage>
      <div className="text-center">
        <h2 className="text-xl text-center text-white mt-10 mb-20 font-semibold">
          Your account has been disabled.
        </h2>
        <Button
          label="Log out"
          color="primary"
          onClick={AuthStore.actions.logout}
        />
      </div>
    </AccessPage>
  );
};

export { AccountDeleted };
