import { createStore } from "version-one-dev-utils/state";
import PropTypes from "prop-types";

import { Firebase } from "../Firebase";

import { AuthStore } from "./AuthStore";

let unsubscribeUser;

const watchUser = (props) => (resolve, reject) => {
  unsubscribeUser && unsubscribeUser();

  const onSnapshot = (querySnapshot) => {
    const invites = [];

    querySnapshot.forEach((doc) => {
      invites.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    resolve(invites);
  };

  unsubscribeUser = Firebase.firestore()
    .collection("invites")
    .where("to", "==", props.id)
    .onSnapshot(onSnapshot, reject);
};

watchUser.propTypes = {
  id: PropTypes.string,
};

watchUser.success = (state, action) => ({
  ...state,
  user: action.payload,
});

let unsubscribeProduction;

const watchProduction = (props) => (resolve, reject) => {
  unsubscribeProduction && unsubscribeProduction();

  const onSnapshot = (querySnapshot) => {
    const invites = [];

    querySnapshot.forEach((doc) => {
      invites.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    resolve(invites);
  };

  unsubscribeProduction = Firebase.firestore()
    .collection("invites")
    .where("production", "==", props.id)
    .onSnapshot(onSnapshot, reject);
};

watchProduction.propTypes = {
  id: PropTypes.string,
};

watchProduction.success = (state, action) => ({
  ...state,
  production: action.payload,
});

const unwatchProduction = () => (resolve) => {
  if (unsubscribeProduction) {
    unsubscribeProduction();
    unsubscribeProduction = null;
  }
  return resolve();
};

unwatchProduction.success = (state, action) => ({
  ...state,
  production: [],
});

const invite = (props) => {
  return fetch(
    `${process.env.REACT_APP_FIREBASE_FUNCTION_HOSTNAME}/inviteUser`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...props,
        modifiedBy: AuthStore.getState().id,
        appHostname: process.env.REACT_APP_HOSTNAME,
      }),
    }
  )
    .then((res) => {
      if (res.status === 200) {
        return Promise.resolve();
      } else {
        return res.json().then((resJson) => {
          return Promise.reject(resJson.error);
        });
      }
    })
    .catch((error) => {
      console.error("Error", String(error));
      return Promise.reject(new Error(String(error)));
    });
};

invite.propTypes = {
  production: PropTypes.string.isRequired,
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const remove = (props) => {
  return Firebase.firestore().collection("invites").doc(props.id).delete();
};

remove.propTypes = {
  id: PropTypes.string.isRequired,
};

export const InviteStore = createStore({
  name: "InviteStore",
  initialState: {
    user: [],
    production: [],
  },
  actions: {
    watchUser,
    watchProduction,
    unwatchProduction,
    invite,
    remove,
  },
  propTypes: PropTypes.shape({
    user: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        production: PropTypes.string.isRequired,
        from: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
      })
    ),
    production: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        production: PropTypes.string.isRequired,
        from: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
      })
    ),
  }),
});
